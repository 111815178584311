import React, {FunctionComponent, useState} from "react"
import InvestorDashboardInvestmentLastUpdated from "../last-updated/last-updated.component"
import InvestorDashboardInvestmentsDesktopRow from "./row/row.component"
import InvestorDashboardInvestmentsDesktopTableHead from "./head/head.component"
import {compare} from "./head/sort/sort.util"
import {InvestmentDashboardDesktopSort} from "./head/sort/sort.type"
import DealFilter, {FilterProps} from "../../../../../tech/filter/filter.component"
import FilterSelection from "../../../../../tech/filter/selection/selection.component"
import {InvestorOnboardingType} from "../../../onboarding/onboarding.type"
import {DashboardType} from "../../dashboard.type"
import {FilterType} from "../../../../../tech/filter/filter.type"
import {FilterTypeUpdateSource} from "../../dashboard.component"
import InvestorDashboardIndividualInvestmentButton from "../individual/button/button.component"
import styles from "./desktop.module.sass"
import {IndividualInvestmentPopupStateType} from "../../../../investment/individual/popup/popup.type"

type InvestorDashboardInvestmentsDesktopProps = {
    dashboard: DashboardType
    filter: FilterProps
    onboarding: InvestorOnboardingType
    updateIndividualInvestmentPopup: (state: IndividualInvestmentPopupStateType) => void
    updateFilterTypesAndSource: (updatedFilterTypes: FilterType[], source: FilterTypeUpdateSource) => void
}

const InvestorDashboardInvestmentsDesktop: FunctionComponent<InvestorDashboardInvestmentsDesktopProps> = ({
    dashboard,
    filter,
    onboarding,
    updateIndividualInvestmentPopup,
    updateFilterTypesAndSource
}) => {
    const [sort, setSort] = useState<InvestmentDashboardDesktopSort>({
        direction: "DESCENDING",
        type: "INVESTMENT_DATE"
    })

    const updateSort = (sort: InvestmentDashboardDesktopSort) => setSort(sort)

    const lastUpdated = dashboard.investments.length > 0
        && dashboard.investments.map(i => i.lastUpdated).reduce((a, b) => a > b ? a : b)

    const updateFilterTypes = (updatedFilterTypes: FilterType[]) => updateFilterTypesAndSource(updatedFilterTypes, "TABLE")

    return (
        <div className={styles.desktop}>
            <div className={styles.head}>
                <div className={styles.title}>
                    <div>
                        Portfolio
                        <div className={styles.subTitle}>
                            <div>
                                ({dashboard.investments.length} Investments)
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.actions}>
                    <div>
                        <InvestorDashboardIndividualInvestmentButton openPopup={() => updateIndividualInvestmentPopup({ type: "NEW" })}/>
                        <FilterSelection
                            filterTypes={filter.filterTypes}
                            updateFilterTypes={updateFilterTypes}
                        />
                        <DealFilter {...{
                            ...filter,
                            updateFilterTypes
                        }}/>
                    </div>
                </div>
            </div>
            {dashboard.investments.length > 0 ? (
                <div className={styles.table}>
                    <InvestorDashboardInvestmentsDesktopTableHead
                        sort={sort}
                        updateSort={updateSort}
                        onboarding={onboarding}
                    />
                    <div className={styles.spacer}>
                        <div/>
                    </div>
                    {dashboard.investments
                        .sort(compare(sort))
                        .map(investment => (
                            <InvestorDashboardInvestmentsDesktopRow
                                investment={investment}
                                targetCurrency={dashboard.currency}
                                onboarding={onboarding}
                                updateIndividualInvestmentPopup={updateIndividualInvestmentPopup}
                                key={`desktop-${investment.investment.investment.value.id}`}
                            />
                        ))
                    }
                </div>
            ) : (
                <div className={styles.noInvestments}>
                    No investments found.
                </div>
            )}
            {lastUpdated && (
                <div className={styles.lastUpdated}>
                    <InvestorDashboardInvestmentLastUpdated
                        lastUpdated={lastUpdated}
                        showOnDesktop={true}
                    />
                </div>
            )}
        </div>
    )
}

export default InvestorDashboardInvestmentsDesktop