import React, {useState} from "react"
import {SubmitHandler} from "react-hook-form"
import {useSearchParams} from "react-router-dom"
import {signIn as signInAmplify} from "aws-amplify/auth"
import ResetPasswordLink from "../reset-password/link/link.component"
import {SignInType} from "./sign-in.type"
import LoadingDots from "../../loading/dots/dots.component"
import Alert from "../../alert/alert.component"
import {AlertType} from "../../alert/type.enum"
import {sendJavaScriptError} from "../../logging/error-logger"
import {ErrorState, isErrorState, State} from "./state.type"
import {evaluateError, evaluateErrorFromNextSignInStep, mapErrorToString} from "./error.util"
import AuthHead from "../head/head.component"
import SignInForm from "./form.component"
import ActionLine from "../action-line.component"
import {SIGN_UP, WAITLIST} from "../../../paths"
import {removeQueryParameters} from "../../routing/parameters.util"
import {useApp} from "../../app/context/app.context"
import {FetchClient} from "../../fetch/client"
import styles from "./sign-in.module.sass"

const SignIn = () => {
    const app = useApp()
    const [searchParams, setSearchParams] = useSearchParams()
    const [state, setState] = useState<State>("NONE")

    const onSubmit: SubmitHandler<SignInType> = async (signInData) => {
        setState("LOADING")
        try {
            const result = await signIn(
                signInData.email,
                signInData.password,
                app.fetchClient
            )
            if (result !== "SUCCESSFULLY_SIGNED_IN") {
                setState(result)
            }
        }
        catch (err) {
            const state = evaluateError(err)
            if (state === "ERROR") {
                await sendJavaScriptError(err, app.fetchClient)
            }
            setState(state)
            // Remove GET parameters
            setSearchParams(removeQueryParameters(searchParams, ["confirmed", "changedPassword"]))
        }
    }

    return (
        <>
            <AuthHead title="Sign In"/>
            {state === "LOADING" && <LoadingDots/>}
            {isErrorState(state) && (
                <div className={styles.alert}>
                    <Alert
                        type={AlertType.ERROR}
                        text={mapErrorToString(state as ErrorState)}
                    />
                </div>
            )}
            {(state === "NONE" || isErrorState(state)) && (
                <>
                    <div>
                        <SignInForm onSubmit={onSubmit}/>
                        <ResetPasswordLink/>
                        <ActionLine
                            intro="Need to create an account?"
                            linkText="Sign up"
                            route={SIGN_UP}
                        />
                        <ActionLine
                            intro="Not a member yet?"
                            linkText="Join our waitlist"
                            route={WAITLIST}
                            withDashedTopLine={false}
                        />
                    </div>
                </>
            )}
        </>
    )
}

export default SignIn

async function signIn(
    username: string,
    password: string,
    fetchClient: FetchClient
) {
    try {
        const result = await signInAmplify({
            username,
            password
        })
        if (!result.isSignedIn) {
            return evaluateErrorFromNextSignInStep(result.nextStep.signInStep)
        }
    }
    catch (err) {
        const error = evaluateError(err)
        if (error === "ERROR") {
            await sendJavaScriptError(err, fetchClient)
        }
        return error
    }
    return "SUCCESSFULLY_SIGNED_IN"
}
