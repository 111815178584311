import {InvestmentType} from "../../../investment/investment.type"
import {IndividualInvestmentType} from "../../../investment/individual/individual-investment.type"
import {ACTIVE_STRING, assembleExitString, EXIT_STRING} from "../../../deal/deal.type"

type InvestorDashboardUnderlyingInvestmentContentType  = {
    type: "INDIVIDUAL",
    value: IndividualInvestmentType
} | {
    type: "REGULAR",
    value: InvestmentType
}

export class InvestorDashboardUnderlyingInvestmentType {
    public investment: InvestorDashboardUnderlyingInvestmentContentType

    constructor(investment: InvestorDashboardUnderlyingInvestmentContentType) {
        this.investment = investment
    }

    public getName(): string {
        return this.investment.type === "INDIVIDUAL"
            ? this.investment.value.name
            : this.investment.value.deal.name
    }

    public getAmountInvested(): number {
        return this.investment.type === "INDIVIDUAL"
            ? this.investment.value.investedAmount
            : this.investment.value.amount
    }

    public getExitString(): string {
        return this.investment.type === "INDIVIDUAL"
            ? (this.investment.value.exited ? EXIT_STRING : ACTIVE_STRING)
            : assembleExitString(this.investment.value.deal);
    }

    public getInvestmentDate(): Date {
        return this.investment.type === "INDIVIDUAL"
            ? this.investment.value.valuations[0].date
            : this.investment.value.deal._finalDeadline
    }

}