import {FileApiClient} from "../../generated"
import {UploadedFileType} from "./file.type"
import {mapOpenApiToFile} from "./file.mapper"

export class FileApi {

    constructor(private apiClient: FileApiClient) {
    }

    public async getDownloadLink(id: string): Promise<string> {
        return (await this.apiClient.fileGetDownloadLink({ id })).link
    }

    public async upload(file: File, identifier?: string): Promise<UploadedFileType> {
        const savedFile = await this.apiClient.fileUploadAsUser({
            file,
            identifier
        })
        return {
            data: file,
            file: mapOpenApiToFile(savedFile),
            uploaded: true
        }
    }

}