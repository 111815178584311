import React, {FunctionComponent, useEffect, useMemo} from "react"
import {useApp} from "./context/app.context"
import NotAuthenticatedRoute from "../routing/not-authenticated/not-authenticated.component"
import {matchPath, Route, Routes, useLocation, useNavigate} from "react-router-dom"
import {
    AUTHENTICATED_AND_NOT_AUTHENTICATED_PATHS,
    CHANGE_PASSWORD,
    DEAL_BASE_PATH,
    HELP_CENTER,
    NOT_AUTHENTICATED_PATHS,
    PRIVACY_STATEMENT,
    RESET_PASSWORD,
    SIGN_IN,
    SIGN_UP,
    TERMS_AND_CONDITIONS,
    VERIFY_EMAIL,
    WAITLIST
} from "../../paths"
import SignIn from "../auth/sign-in/sign-in.component"
import ChangePassword from "../auth/reset-password/change/change.component"
import HelpCenter from "../../domain/help/help.component"
import VerifyEmail from "../auth/verify-email/verify-email.component"
import ResetPassword from "../auth/reset-password/reset-password.component"
import SignUp from "../auth/sign-up/sign-up.component"
import Waitlist from "../../domain/waitlist/waitlist.component"
import AuthenticatedRoute from "../routing/authenticated/authenticated.component"
import {InvestorOnboardingType} from "../../domain/investor/onboarding/onboarding.type"
import {
    QUERY__INVESTOR_ONBOARDING,
    QUERY_KEY__INVESTOR_ONBOARDING
} from "../../domain/investor/onboarding/onboarding.query"
import {evaluateEntryPath, evaluateRelevantRoutes} from "../routing/routing.util"
import {useQueries, useQuery, useQueryClient} from "react-query"
import DealOverview from "../../domain/deal/overview.component"
import DealSingle from "../../domain/deal/single/single.component"
import DealInvestmentInitialization from "../../domain/deal/invest/initialization.component"
import ExclusiveDeals from "../../domain/deal/exclusive/exclusive-deals.component"
import FavouriteDeals from "../../domain/deal/favourite/favourite-deals.component"
import InvestorDashboard from "../../domain/investor/dashboard/dashboard.component"
import InvestorOnboarding from "../../domain/investor/onboarding/onboarding.component"
import PendingInvestmentsOverview from "../../domain/investment/pending/overview.component"
import Profile from "../../domain/investor/profile/profile.component"
import Syndication from "../../domain/syndicate/syndication/syndication.component"
import {RouteData} from "../routing/route.type"
import {assembleQueriesToPrefetch} from "../prefetch/prefetch.util"
import {QUERY__DEAL_OVERVIEW__DEALS} from "../../domain/deal/overview.config"
import {QUERY__DEALS} from "../../domain/deal/deal.query"
import {
    redirectToPreviousPathAfterSignInIfNecessary,
    redirectToSignInAndStoreCurrentUrl,
    removePreviousPath,
    shouldRedirectToPreviousPathAfterSignIn,
    shouldRemovePreviousPath
} from "../redirect/redirect.util"
import PrivacyStatement from "../../domain/legal/privacy/privacy-statement.component"
import TermsAndConditions from "../../domain/legal/terms/terms-and-conditions.component"

type AppNotLoadingProps = {
    alreadyRedirected: boolean
    updateAlreadyRedirected: (alreadyRedirected: boolean) => void
}

const AppNotLoading: FunctionComponent<AppNotLoadingProps> = ({
    alreadyRedirected,
    updateAlreadyRedirected
}) => {
    const app = useApp()
    const location = useLocation()
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const onboardingResult = useQuery(QUERY__INVESTOR_ONBOARDING(
        app.fetchClient,
        QUERY_KEY__INVESTOR_ONBOARDING(),
        app.authenticated
    ))
    const openDealsResult = useQuery(QUERY__DEALS(
        app.fetchClient,
        queryClient,
        QUERY__DEAL_OVERVIEW__DEALS,
        app.authenticated
    ))
    const onboarding = onboardingResult.data
    const openDeals = openDealsResult.data

    const relevantRoutes = useMemo(
        () => {
            return onboarding && openDeals ? evaluateRelevantRoutes(
                openDeals,
                onboarding
            ) : []
        },
        [onboarding, openDeals]
    )

    useEffect(() => {
        if (shouldRemovePreviousPath(location.pathname)) {
            removePreviousPath()
        }
        else {
            // Redirect to entry (authenticated)
            if (onboarding && openDeals && shouldRedirectToAuthenticatedEntry(app.authenticated, relevantRoutes, location.pathname) && !alreadyRedirected) {
                navigate(evaluateEntryPath(
                    openDeals,
                    onboarding
                ))
            }
        }

        // Redirect to previous path
        const shouldRedirectToPreviousPath = app.authenticated
            && onboarding
            && openDeals
            && relevantRoutes
            && shouldRedirectToPreviousPathAfterSignIn(relevantRoutes)
        if (shouldRedirectToPreviousPath) {
            updateAlreadyRedirected(true)
            redirectToPreviousPathAfterSignInIfNecessary(navigate)
        }
    }, [
        alreadyRedirected,
        updateAlreadyRedirected,
        app.authenticated,
        location.pathname,
        onboarding,
        openDeals,
        navigate,
        relevantRoutes
    ])
    
    useEffect(() => {
        // Redirect to sign in (not authenticated)
        if (shouldRedirectToSignInIfNotAuthenticated(app.authenticated, location.pathname)) {
            redirectToSignInAndStoreCurrentUrl(
                location.pathname,
                navigate
            )
        }

        // Redirect from root
        if (["", "/"].includes(location.pathname) && !app.authenticated) {
            redirectToSignInAndStoreCurrentUrl(
                location.pathname,
                navigate
            )
        }
    }, [
        app.authenticated,
        location.pathname,
        navigate
    ])

    useQueries(assembleQueriesToPrefetch(
        app.authenticated,
        app.fetchClient,
        queryClient,
        location.pathname
    ))

    const updateOnboarding = (updatedOnboarding: InvestorOnboardingType) => queryClient.setQueryData(
        QUERY_KEY__INVESTOR_ONBOARDING(),
        updatedOnboarding
    )

    return (
        <Routes>
            <Route
                path={DEAL_BASE_PATH + `:id`}
                element={(
                    <DealSingle
                        onboarding={onboarding}
                        openDeals={openDeals!}
                        relevantRoutes={relevantRoutes}
                    />
                )}
            />
            <Route
                path={PRIVACY_STATEMENT}
                element={(
                    <PrivacyStatement
                        onboarding={onboarding}
                        openDeals={openDeals}
                        relevantRoutes={relevantRoutes}
                    />
                )}
            />
            <Route
                path={TERMS_AND_CONDITIONS}
                element={(
                    <TermsAndConditions
                        onboarding={onboarding}
                        openDeals={openDeals}
                        relevantRoutes={relevantRoutes}
                    />
                )}
            />
            <Route element={(
                <AuthenticatedRoute
                    onboarding={onboarding!}
                    openDeals={openDeals!}
                    relevantRoutes={relevantRoutes}
                />
            )}>
                {relevantRoutes.map((route, index) => {
                    let elem
                    switch (route.identifier) {
                        case "DEALS":
                            elem = (
                                <DealOverview
                                    onboarding={onboarding!}
                                    relevantRoutes={relevantRoutes}
                                />
                            )
                            break
                        case "DEAL_INVEST":
                            elem = (
                                <DealInvestmentInitialization onboarding={onboarding!}/>
                            )
                            break
                        case "EXCLUSIVE_DEALS":
                            elem = <ExclusiveDeals onboarding={onboarding!}/>
                            break
                        case "FAVOURITE_DEALS":
                            elem = <FavouriteDeals onboarding={onboarding!}/>
                            break
                        case "HELP":
                            elem = <HelpCenter authenticated/>
                            break
                        case "INVESTOR_DASHBOARD":
                            elem = <InvestorDashboard onboarding={onboarding!}/>
                            break
                        case "ONBOARDING":
                            elem = (
                                <InvestorOnboarding
                                    onboarding={onboarding!}
                                    updateOnboarding={updateOnboarding}
                                />
                            )
                            break
                        case "PENDING_INVESTMENTS":
                            elem = <PendingInvestmentsOverview onboarding={onboarding!}/>
                            break
                        // case "PREMIUM_MEMBERSHIP":
                        //     elem = (
                        //         <PremiumMembership
                        //             onboarding={onboarding}
                        //             updateOnboarding={updateOnboarding}
                        //         />
                        //     )
                        //     break
                        // case "PREMIUM_MEMBERSHIP_CANCEL":
                        //     elem = (
                        //         <SubscriptionCancel
                        //             onboarding={onboarding}
                        //             updateOnboarding={updateOnboarding}
                        //         />
                        //     )
                        //     break
                        // case "PREMIUM_MEMBERSHIP_REACTIVATE":
                        //     elem = (
                        //         <SubscriptionReactivate
                        //             onboarding={onboarding}
                        //             updateOnboarding={updateOnboarding}
                        //         />
                        //     )
                        //     break
                        case "PROFILE":
                            elem = <Profile onboarding={onboarding!}/>
                            break
                        case "SYNDICATION":
                            elem = <Syndication onboarding={onboarding!}/>
                            break
                    }
                    return (
                        <Route
                            path={route.path}
                            element={elem}
                            key={`${route.path}-${index}`}
                        />
                    )
                })}
            </Route>
            <Route element={(
                <NotAuthenticatedRoute
                    onboarding={onboarding}
                    openDeals={openDeals}
                />
            )}>
                <Route
                    path={CHANGE_PASSWORD}
                    element={<ChangePassword/>}
                />
                <Route
                    path={HELP_CENTER}
                    element={<HelpCenter authenticated={false}/>}
                />
                <Route
                    path={VERIFY_EMAIL}
                    element={<VerifyEmail/>}
                />
                <Route
                    path={RESET_PASSWORD}
                    element={<ResetPassword/>}
                />
                <Route
                    path={SIGN_IN}
                    element={<SignIn/>}
                />
                <Route
                    path={SIGN_UP}
                    element={<SignUp/>}
                />
                <Route
                    path={WAITLIST}
                    element={<Waitlist/>}
                />
            </Route>
        </Routes>
    )
}

export default AppNotLoading

function shouldRedirectToAuthenticatedEntry(
    authenticated: boolean,
    relevantRoutes: RouteData[],
    pathname: string
): boolean {
    return authenticated
        && !relevantRoutes.some(route => matchPath({ path: route.path }, pathname) !== null)
        && !AUTHENTICATED_AND_NOT_AUTHENTICATED_PATHS.some(path => matchPath({ path: path }, pathname) !== null)
}

function shouldRedirectToSignInIfNotAuthenticated(
    authenticated: boolean,
    pathname: string
): boolean {
    return !authenticated
        && !NOT_AUTHENTICATED_PATHS.some(p => matchPath({ path: p }, pathname) !== null)
        && matchPath({ path: DEAL_BASE_PATH + ":id" }, pathname) === null
}