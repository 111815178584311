import React, {useState} from "react"
import {SubmitHandler} from "react-hook-form"
import {signUp as signUpAmplify} from "aws-amplify/auth"
import LoadingDots from "../../loading/dots/dots.component"
import Alert from "../../alert/alert.component"
import {AlertType} from "../../alert/type.enum"
import {SignUpType} from "./sign-up.type"
import {sendJavaScriptError} from "../../logging/error-logger"
import {ErrorState, isErrorState, State} from "./state.type"
import {evaluateError, mapErrorToString} from "./error.util"
import AuthHead from "../head/head.component"
import SignUpForm from "./form.component"
import ActionLine from "../action-line.component"
import {buildUserAttributes} from "../../../domain/syndicate/invitation-code.util"
import {SIGN_IN} from "../../../paths"
import {useApp} from "../../app/context/app.context"
import InfoCircleIcon from "../../icon/info-circle.component"
import styles from "./sign-up.module.sass"

const SignUp = () => {
    const app = useApp()
    const [state, setState] = useState<State>("NONE")

    const onSubmit: SubmitHandler<SignUpType> = async (signUp) => {
        try {
            setState("LOADING")
            await signUpAmplify({
                username: signUp.email,
                password: signUp.password,
                options: buildUserAttributes(signUp.invitationCode)
            })
            setState("TO_BE_CONFIRMED")
        }
        catch (err) {
            console.error(err)
            const state = evaluateError(err)
            if (state === "ERROR") {
                await sendJavaScriptError(err, app.fetchClient)
            }
            setState(state)
        }
    }

    return (
        <>
            <AuthHead title={state === "TO_BE_CONFIRMED" ? "Account created" : "Create an account"}/>
            {state === "LOADING" && <LoadingDots/>}
            {isErrorState(state) && (
                <div className={styles.alert}>
                    <Alert
                        type={AlertType.ERROR}
                        text={mapErrorToString(state as ErrorState)}
                    />
                </div>
            )}
            {(state === "NONE" || isErrorState(state)) && (
                <>
                    <div className={styles.form}>
                        <SignUpForm onSubmit={onSubmit}/>
                    </div>
                    <ActionLine
                        intro="Already a member?"
                        linkText="Sign in"
                        route={SIGN_IN}
                    />
                </>
            )}
            {state === "TO_BE_CONFIRMED" && (
                <div className={styles.toBeConfirmed}>
                    <div>
                        <InfoCircleIcon
                            width={32}
                            height={32}
                            fillClass="fill-icon-warning"
                        />
                    </div>
                    <div>
                        <div>
                            Sign up successful. You will receive a mail with instructions to verify your account.
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default SignUp