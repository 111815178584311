import {LoggingApiClient} from "../../generated"

export class LoggingApi {

    constructor(private apiClient: LoggingApiClient) {
    }

    public async sendError(
        date: Date,
        error: string,
        frontendUrl: string
    ): Promise<void> {
        await this.apiClient.logFrontendError({
            javaScriptErrorOpenApi: {
                date,
                error: this.enforceString(error),
                frontendUrl,
                userAgent: this.getUserAgent(),
                stacktrace: this.getStacktrace()
            }
        })
    }

    public async sendRequestError(
        date: Date,
        error: string | undefined,
        frontendUrl: string,
        requestUrl: string,
        responseStatus: number|undefined
    ): Promise<void> {
        await this.apiClient.logFrontendRequestError({
            javaScriptRequestErrorOpenApi: {
                date,
                error: this.enforceString(error),
                frontendUrl,
                requestUrl,
                responseStatus,
                userAgent: this.getUserAgent(),
                stacktrace: this.getStacktrace()
            }
        })
    }

    private enforceString(err: any): string {
        return err === undefined
            ? ""
            : err.toString()
    }

    private getUserAgent() {
        try {
            return navigator.userAgent
        }
        catch (ignored) {
            return "-"
        }
    }

    private getStacktrace() {
        try {
            const error = new Error()
            return error.stack
        }
        catch (ignored) {
            return "-"
        }
    }

}