/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FileTypeOpenApi } from './FileTypeOpenApi';
import {
    FileTypeOpenApiFromJSON,
    FileTypeOpenApiFromJSONTyped,
    FileTypeOpenApiToJSON,
} from './FileTypeOpenApi';
import type { MimeTypeOpenApi } from './MimeTypeOpenApi';
import {
    MimeTypeOpenApiFromJSON,
    MimeTypeOpenApiFromJSONTyped,
    MimeTypeOpenApiToJSON,
} from './MimeTypeOpenApi';

/**
 * 
 * @export
 * @interface FFileOpenApi
 */
export interface FFileOpenApi {
    /**
     * 
     * @type {string}
     * @memberof FFileOpenApi
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof FFileOpenApi
     */
    sequentialId: number;
    /**
     * 
     * @type {Date}
     * @memberof FFileOpenApi
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FFileOpenApi
     */
    modified?: Date;
    /**
     * 
     * @type {string}
     * @memberof FFileOpenApi
     */
    path: string;
    /**
     * 
     * @type {string}
     * @memberof FFileOpenApi
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof FFileOpenApi
     */
    imgixUrl?: string;
    /**
     * 
     * @type {FileTypeOpenApi}
     * @memberof FFileOpenApi
     */
    type: FileTypeOpenApi;
    /**
     * 
     * @type {MimeTypeOpenApi}
     * @memberof FFileOpenApi
     */
    mimeType: MimeTypeOpenApi;
    /**
     * 
     * @type {number}
     * @memberof FFileOpenApi
     */
    bytes?: number;
    /**
     * 
     * @type {string}
     * @memberof FFileOpenApi
     */
    identifier?: string;
}

/**
 * Check if a given object implements the FFileOpenApi interface.
 */
export function instanceOfFFileOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "sequentialId" in value;
    isInstance = isInstance && "path" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "mimeType" in value;

    return isInstance;
}

export function FFileOpenApiFromJSON(json: any): FFileOpenApi {
    return FFileOpenApiFromJSONTyped(json, false);
}

export function FFileOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): FFileOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sequentialId': json['sequentialId'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'path': json['path'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'imgixUrl': !exists(json, 'imgixUrl') ? undefined : json['imgixUrl'],
        'type': FileTypeOpenApiFromJSON(json['type']),
        'mimeType': MimeTypeOpenApiFromJSON(json['mimeType']),
        'bytes': !exists(json, 'bytes') ? undefined : json['bytes'],
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
    };
}

export function FFileOpenApiToJSON(value?: FFileOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sequentialId': value.sequentialId,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'modified': value.modified === undefined ? undefined : (value.modified.toISOString()),
        'path': value.path,
        'url': value.url,
        'imgixUrl': value.imgixUrl,
        'type': FileTypeOpenApiToJSON(value.type),
        'mimeType': MimeTypeOpenApiToJSON(value.mimeType),
        'bytes': value.bytes,
        'identifier': value.identifier,
    };
}

