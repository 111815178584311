import React, {FunctionComponent, useState} from "react"
import {mapSalutationToString} from "../../../../person/person.type"
import {countries} from "../../../../address/country/country.type"
import {SubmitHandler, useForm} from "react-hook-form"
import {InvestorOnboardingType} from "../../onboarding.type"
import {normalizeValues} from "../../../../../tech/form/form.util"
import SubmitButton from "../../../../../tech/form/submit/submit.component"
import SelectField from "../../../../../tech/form/fields/select/select.component"
import {SalutationOpenApi} from "../../../../../generated"
import LoadingDots from "../../../../../tech/loading/dots/dots.component"
import Alert from "../../../../../tech/alert/alert.component"
import {AlertType} from "../../../../../tech/alert/type.enum"
import FormRow from "../../../../../tech/form/row/row.component"
import TextField from "../../../../../tech/form/fields/text/text.component"
import EmailField from "../../../../../tech/form/fields/email/email.component"
import ButtonWrapper from "../../../../../tech/button/wrapper/button-wrapper.component"
import {useApp} from "../../../../../tech/app/context/app.context"
import styles from "./form.module.sass"
import Checkbox from "../../../../../tech/form/fields/checkbox/checkbox.component"
import {GeneralDataFormType} from "./form.type"
import ExclamationMark from "../../../../../tech/exclamation-mark/mark.component"
import ExclamationMarkInfo from "../../../../../tech/exclamation-mark/info.component"
import PoliticallyExposedPersonExplanation from "./politically-exposed-person-explanation.component"

type GeneralDataFormProps = {
    updateOnboarding: (onboarding: InvestorOnboardingType) => void
}

const GeneralDataForm: FunctionComponent<GeneralDataFormProps> = ({ updateOnboarding }) => {
    const app = useApp()
    const [state, setState] = useState<"LOADING" | "NONE" | "ERROR">("NONE")

    const {
        register,
        handleSubmit,
        formState: {
            errors,
            isDirty,
            isValid
        }
    } = useForm<GeneralDataFormType>({
        mode: "onChange"
    })

    const onSubmit: SubmitHandler<GeneralDataFormType> = async (data) => {
        setState("LOADING")
        try {
            updateOnboarding(await app.fetchClient.investorApi.saveOnboardingGeneralData(normalizeValues(data)))
        } catch (err) {
            setState("ERROR")
        }
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "NONE" && (
                <div className={styles.generalDataForm}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={styles.fields}>
                            <FormRow content={{
                                type: "one",
                                element: (
                                    <SelectField
                                        label="Salutation"
                                        options={Object.entries(SalutationOpenApi).map(c => {
                                            return {
                                                label: mapSalutationToString(c[1]),
                                                value: c[1]
                                            }
                                        })}
                                        errors={errors}
                                        reactHookFormRegister={{
                                            name: "salutation",
                                            register
                                        }}
                                    />
                                )
                            }}/>

                            <FormRow content={{
                                type: "two",
                                firstElement: (
                                    <TextField
                                        placeholder="First name"
                                        errors={errors}
                                        reactHookFormRegister={{
                                            name: "firstName",
                                            register,
                                            options: {
                                                required: "First name is required.",
                                                minLength: { value: 2, message: "First name must be at least 2 characters long." },
                                            }
                                        }}
                                    />
                                ),
                                secondElement: (
                                    <TextField
                                        placeholder="Last Name"
                                        errors={errors}
                                        reactHookFormRegister={{
                                            name: "lastName",
                                            register,
                                            options: {
                                                required: "Last name is required.",
                                                minLength: { value: 2, message: "Last name must be at least 2 characters long." },
                                            }
                                        }}
                                    />
                                )
                            }}/>

                            <FormRow content={{
                                type: "one",
                                element: (
                                    <TextField
                                        placeholder="Address line 1"
                                        errors={errors}
                                        reactHookFormRegister={{
                                            name: "address.line1",
                                            register,
                                            options: {
                                                required: "Address line 1 is required.",
                                                minLength: { value: 2, message: "Too short." }
                                            }
                                        }}
                                    />
                                )
                            }}/>

                            <FormRow content={{
                                type: "one",
                                element: (
                                    <TextField
                                        placeholder="Address line 2"
                                        errors={errors}
                                        reactHookFormRegister={{
                                            name: "address.line2",
                                            register
                                        }}
                                    />
                                )
                            }}/>

                            <FormRow content={{
                                type: "two",
                                firstElement: (
                                    <TextField
                                        placeholder="ZIP / Postal code"
                                        errors={errors}
                                        reactHookFormRegister={{
                                            name: "address.zipOrPostalCode",
                                            register,
                                            options: {
                                                required: "ZIP / Postal code is required.",
                                                minLength: { value: 2, message: "ZIP / Postal code must be at least 2 characters long." }
                                            }
                                        }}
                                    />
                                ),
                                secondElement: (
                                    <TextField
                                        placeholder="City"
                                        errors={errors}
                                        reactHookFormRegister={{
                                            name: "address.city",
                                            register,
                                            options: {
                                                required: "City is required.",
                                                minLength: { value: 2, message: "City must be at least 2 characters long." }
                                            }
                                        }}
                                    />
                                )
                            }}/>

                            <FormRow content={{
                                type: "one",
                                element: (
                                    <TextField
                                        placeholder="State / Province / Region"
                                        errors={errors}
                                        reactHookFormRegister={{
                                            name: "address.stateOrProvinceOrRegion",
                                            register,
                                            options: {
                                                minLength: { value: 2, message: "State / Province / Region must be at least 2 characters long." }
                                            }
                                        }}
                                    />
                                )
                            }}/>

                            <FormRow content={{
                                type: "one",
                                element: (
                                    <Checkbox
                                        label={(
                                            <div>I'm <strong><u>not</u></strong> a <strong>politically exposed person</strong></div>
                                        )}
                                        id="notPoliticallyExposed"
                                        errors={errors}
                                        reactHookFormRegister={{
                                            name: "notPoliticallyExposed",
                                            register
                                        }}
                                        exclamationMark={{
                                            mark: <ExclamationMark modalId="politicallyExposedPersonExplanation"/>,
                                            info: (
                                                <ExclamationMarkInfo
                                                    modalId="politicallyExposedPersonExplanation"
                                                    title="Confirm that you're not a politically exposed person"
                                                    content={<PoliticallyExposedPersonExplanation/>}
                                                />
                                            ),
                                            labelFullWidth: false
                                        }}
                                    />
                                )
                            }}/>

                            <FormRow content={{
                                type: "one",
                                element: (
                                    <SelectField
                                        label="Country"
                                        options={countries.map(country => {
                                            return {
                                                label: country.name,
                                                value: country.code
                                            }
                                        })}
                                        errors={errors}
                                        reactHookFormRegister={{
                                            name: "address.country",
                                            register,
                                            options: {
                                                required: "Country is required."
                                            }
                                        }}
                                    />
                                )
                            }}/>

                            {app.currentUser?.email ? (
                                <input
                                    type="hidden"
                                    value={app.currentUser.email}
                                    {...register("contact.email")}
                                />
                            ) : (
                                <FormRow content={{
                                    type: "one",
                                    element: (
                                        <EmailField
                                            placeholder="Your Email"
                                            errors={errors}
                                            reactHookFormRegister={{
                                                name: "contact.email",
                                                register,
                                                options: {
                                                    required: "Email is required."
                                                }
                                            }}
                                        />
                                    )
                                }}/>
                            )}

                            <FormRow content={{
                                type: "one",
                                element: (
                                    <TextField
                                        label="Company Name (optional)"
                                        placeholder="Company Name"
                                        errors={errors}
                                        reactHookFormRegister={{
                                            name: "companyName",
                                            register
                                        }}
                                    />
                                )
                            }}/>
                        </div>

                        <ButtonWrapper rightOnDesktop={true}>
                            <SubmitButton
                                label="Submit"
                                disabled={!isValid || !isDirty}
                            />
                        </ButtonWrapper>
                    </form>
                </div>
            )}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Unexpected error."
                />
            )}
        </>
    )
}

export default GeneralDataForm
