import React, {FunctionComponent} from "react"
import DealHead from "../head/head.component"
import {InvestmentType} from "../../../investment/investment.type"
import {InvestorOnboardingType} from "../../../investor/onboarding/onboarding.type"
import {DealInterface} from "../../deal.interface"
import DealFees from "./fees/fees.component"
import {DealOnboardingDependentType} from "../../onboarding-dependent.type"
import DealCommitment from "../../card/commitment/commitment.component"
import {formatDate} from "../../../../tech/format/datetime.util"
import {determineTimelineItems} from "../../../investment/timeline/timeline.util"
import InvestmentTimeline from "../../../investment/timeline/timeline.component"
import DealInvestButton from "../invest-button/invest-button.component"
import DealTags from "../../card/tags/tags.component"
import DealInvestors from "../../card/investors/investors.component"
import DealCategorization from "../../card/categorization/categorization.component"
import {useLocation, useNavigate} from "react-router-dom"
import Button from "../../../../tech/button/button.component"
import {ButtonSize} from "../../../../tech/button/size.enum"
import {ButtonState} from "../../../../tech/button/state.enum"
import {ButtonStyle} from "../../../../tech/button/style.enum"
import {redirectToSignUpAndStoreCurrentUrl} from "../../../../tech/redirect/redirect.util"
import styles from "./summary.module.sass"
import DealNotYetPublished from "../../public/not-yet-published.component"

type DealSummaryProps = {
    deal: DealInterface
    investment?: InvestmentType
    onboarding?: InvestorOnboardingType
}

const DealSummary: FunctionComponent<DealSummaryProps> = ({
    deal,
    investment,
    onboarding
}) => {
    const location = useLocation()
    const navigate = useNavigate()

    const addTopStyle = deal.geography
        || deal.industry
        || deal.stage
        || (deal.investors && deal.investors.length > 0)
        || (deal.termsTags && deal.termsTags.length > 0)

    return (
        <div className={styles.summary}>
            <DealNotYetPublished deal={deal}/>
            <DealHead deal={deal}/>
            <div className={addTopStyle ? styles.top : ""}>
                <DealCategorization
                    geography={deal.geography}
                    industry={deal.industry}
                    stage={deal.stage}
                />
                <DealInvestors investors={deal.investors}/>
                <DealTags termsTags={deal.termsTags}/>
            </div>
            <div className={styles.bottom}>
                <DealCommitment
                    deal={deal}
                    investment={investment}
                    blurredPosition="SINGLE"
                />
            </div>
            {!deal.isPublic() && !(deal as DealOnboardingDependentType).imported && (
                <DealFees
                    deal={deal as DealOnboardingDependentType}
                    investment={investment}
                />
            )}
            {investment !== undefined && onboarding !== undefined && (
                <div className={styles.timeline}>
                    <InvestmentTimeline items={determineTimelineItems(
                        onboarding,
                        investment
                    )}/>
                </div>
            )}
            {deal.isPublic() ? (
                <div className={styles.investButton}>
                    <Button
                        label="INVEST"
                        type="button"
                        size={ButtonSize.MEDIUM}
                        state={ButtonState.ACTIVE}
                        style={ButtonStyle.PRIMARY}
                        onClick={() => redirectToSignUpAndStoreCurrentUrl(location.pathname, navigate)}
                        fullWidth={true}
                        withArrow={true}
                    />
                </div>
            ) : (
                <DealInvestButton
                    deal={deal as DealOnboardingDependentType}
                    investment={investment}
                    onboarding={onboarding}
                    position="SUMMARY"
                />
            )}
            {deal.finalDeadline && deal.finalDeadline >= new Date() && (
                <div className={styles.finalDeadline}>
                    Deal closes latest {formatDate(deal.finalDeadline)}
                </div>
            )}
        </div>
    )
}

export default DealSummary