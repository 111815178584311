import React, {FunctionComponent} from "react"
import {IconPropsStrokeType} from "./props.type"

const DownloadIcon: FunctionComponent<IconPropsStrokeType> = ({
    width,
    height,
    strokeClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={strokeClass}
            viewBox="0 0 24 24"
        >
            <path d="M7 12l5 5m0 0l5-5m-5 5V4M6 20h12"/>
        </svg>
    )
}

export default DownloadIcon