import React, {FunctionComponent} from "react"
import {Control, FieldErrors, UseFormRegister} from "react-hook-form"
import {IndividualInvestmentType, IndividualInvestmentValuationType} from "../../individual-investment.type"
import FormRow from "../../../../../tech/form/row/row.component"
import NumberField from "../../../../../tech/form/fields/number/number.component"
import DateField from "../../../../../tech/form/fields/date/date.component"
import styles from "./valuation.module.sass"
import TrashIcon from "../../../../../tech/icon/trash.component"

type IndividualInvestmentFormValuationProps = {
    control: Control<IndividualInvestmentType, any>
    errors: FieldErrors<IndividualInvestmentType>
    register: UseFormRegister<IndividualInvestmentType>
    del: (i: number) => void
    numberOfValuations: number
    index: number
}

const IndividualInvestmentFormValuation: FunctionComponent<IndividualInvestmentFormValuationProps> = ({
    control,
    errors,
    register,
    del,
    numberOfValuations,
    index
}) => {
    return (
        <div className={styles.valuation}>
            <input type="hidden" {...register(`valuations.${index}.id`)} />
            <div className={styles.wrapper}>
                <div>
                    <FormRow content={{
                        type: "two",
                        firstElement: (
                            <NumberField
                                placeholder="Valuation"
                                errors={errors}
                                control={control}
                                reactHookFormRegister={{
                                    name: `valuations.${index}.valuation`,
                                    register,
                                    options: {
                                        required: "Valuation is required.",
                                        min: {value: 0, message: "Must not be negative."},
                                    }
                                }}
                            />
                        ),
                        secondElement: (
                            <DateField
                                placeholder="Date"
                                errors={errors}
                                control={control}
                                reactHookFormRegister={{
                                    name: `valuations.${index}.date`,
                                    register,
                                    options: {
                                        required: "Date is required.",
                                        validate: (value, formValues) => {
                                            if (value !== undefined) {
                                                const allValuationDates = formValues.valuations
                                                    .map((v: IndividualInvestmentValuationType) => v.date)
                                                    .filter((d: Date | undefined) => d !== undefined)
                                                if (allValuationDates.filter((d: Date) => d.getTime() === value.getTime()).length > 1) {
                                                    return "There must not be multiple valuations at the same date."
                                                }
                                            }
                                            return true
                                        }
                                    }
                                }}
                            />
                        ),
                        layout: "LEFT_GROW"
                    }}/>
                </div>
                {numberOfValuations > 1 && (
                    <div className={styles.removeWrapper}>
                        {index !== 0 && (
                            <div
                                className={styles.remove}
                                onClick={() => del(index)}
                            >
                                <TrashIcon
                                    fillClass="fill-icon-caption"
                                    width={24}
                                    height={24}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default IndividualInvestmentFormValuation