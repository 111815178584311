import React, {FunctionComponent} from "react"
import Imgix from "react-imgix"
import {SyndicateType} from "../syndicate.type"
import styles from "./banner.module.sass"

type SyndicateBannerProps = {
    syndicate?: SyndicateType
    placement?: "MOBILE" | "DESKTOP"
}

const SyndicateBanner: FunctionComponent<SyndicateBannerProps> = ({
    syndicate,
    placement
}) => {
    if (syndicate === undefined) return <></>
    return (
        <div className={`${styles.banner} ${placement === "MOBILE" ? styles.mobile : ""} ${placement === "DESKTOP" ? styles.desktop : ""}`}>
            <div className={styles.logo}>
                <Imgix
                    src={syndicate.logo.url!}
                    height={72}
                    htmlAttributes={{alt: syndicate.name}}
                />
            </div>
            <div>
                <div>
                    <div className={styles.title}>
                        Deal by {syndicate.name}
                    </div>
                    <div className={styles.text}>
                        This view is exclusively for your involvement in the <strong>{syndicate.name}</strong> syndicate. All deal details are given by the responsible syndicate manager while NonPublic only acts as a technology provider.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SyndicateBanner