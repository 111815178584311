import {
    DEAL_BASE_PATH,
    DEALS,
    EXCLUSIVE_DEALS,
    FAVOURITE_DEALS,
    HELP,
    INVESTOR_DASHBOARD,
    ONBOARDING,
    PENDING_INVESTMENTS,
    PREMIUM_MEMBERSHIP,
    PREMIUM_MEMBERSHIP_CANCEL,
    PREMIUM_MEMBERSHIP_REACTIVATE,
    PROFILE,
    SYNDICATION
} from "../../paths"
import {RouteData} from "./route.type"

export const routes: RouteData[] = [
    {
        identifier: "DEALS",
        path: DEALS,
        availability: {
            type: "SYNDICATE_ONLY_INVESTORS",
            investorsWithNoDeals: true,
            investorsWithSingleDeal: false,
            investorsWithMultipleDeals: true,
            investorsWithMultipleSyndicates: false
        }
    },
    {
        identifier: "DEAL",
        path: DEAL_BASE_PATH + `:id`,
        availability: {
            type: "SYNDICATE_ONLY_INVESTORS",
            investorsWithNoDeals: false,
            investorsWithSingleDeal: true,
            investorsWithMultipleDeals: true,
            investorsWithMultipleSyndicates: true
        }
    },
    {
        identifier: "DEAL_INVEST",
        path: DEAL_BASE_PATH + `:id/invest`,
        availability: {
            type: "SYNDICATE_ONLY_INVESTORS",
            investorsWithNoDeals: false,
            investorsWithSingleDeal: true,
            investorsWithMultipleDeals: true,
            investorsWithMultipleSyndicates: true
        }
    },
    {
        identifier: "EXCLUSIVE_DEALS",
        path: EXCLUSIVE_DEALS,
        availability: {
            type: "FULL_PLATFORM_ACCESS"
        }
    },
    {
        identifier: "FAVOURITE_DEALS",
        path: FAVOURITE_DEALS,
        availability: {
            type: "FULL_PLATFORM_ACCESS"
        }
    },
    {
        identifier: "HELP",
        path: HELP,
        availability: {
            type: "SYNDICATE_ONLY_INVESTORS",
            investorsWithNoDeals: true,
            investorsWithSingleDeal: true,
            investorsWithMultipleDeals: true,
            investorsWithMultipleSyndicates: true
        }
    },
    {
        identifier: "INVESTOR_DASHBOARD",
        path: INVESTOR_DASHBOARD,
        availability: {
            type: "SYNDICATE_ONLY_INVESTORS",
            investorsWithNoDeals: true,
            investorsWithSingleDeal: true,
            investorsWithMultipleDeals: true,
            investorsWithMultipleSyndicates: true
        }
    },
    {
        identifier: "ONBOARDING",
        path: ONBOARDING,
        availability: {
            type: "SYNDICATE_ONLY_INVESTORS",
            investorsWithNoDeals: true,
            investorsWithSingleDeal: true,
            investorsWithMultipleDeals: true,
            investorsWithMultipleSyndicates: true
        }
    },
    {
        identifier: "PENDING_INVESTMENTS",
        path: PENDING_INVESTMENTS,
        availability: {
            type: "SYNDICATE_ONLY_INVESTORS",
            investorsWithNoDeals: false,
            investorsWithSingleDeal: false,
            investorsWithMultipleDeals: false,
            investorsWithMultipleSyndicates: false
        }
    },
    {
        identifier: "PREMIUM_MEMBERSHIP",
        path: PREMIUM_MEMBERSHIP,
        availability: {
            type: "FULL_PLATFORM_ACCESS"
        }
    },
    {
        identifier: "PREMIUM_MEMBERSHIP_CANCEL",
        path: PREMIUM_MEMBERSHIP_CANCEL,
        availability: {
            type: "FULL_PLATFORM_ACCESS"
        }
    },
    {
        identifier: "PREMIUM_MEMBERSHIP_REACTIVATE",
        path: PREMIUM_MEMBERSHIP_REACTIVATE,
        availability: {
            type: "FULL_PLATFORM_ACCESS"
        }
    },
    {
        identifier: "PROFILE",
        path: PROFILE,
        availability: {
            type: "SYNDICATE_ONLY_INVESTORS",
            investorsWithNoDeals: true,
            investorsWithSingleDeal: true,
            investorsWithMultipleDeals: true,
            investorsWithMultipleSyndicates: true
        }
    },
    {
        identifier: "SYNDICATION",
        path: SYNDICATION,
        availability: {
            type: "SYNDICATE_ONLY_INVESTORS",
            investorsWithNoDeals: false,
            investorsWithSingleDeal: false,
            investorsWithMultipleDeals: false,
            investorsWithMultipleSyndicates: true
        }
    },
]