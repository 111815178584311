import {DealFeeStructureOpenApi} from "../../../generated"
import {DealFeeStructureType} from "./deal-fee-structure.type"

export function mapOpenApiToDealFeeStructure(feeStructure: DealFeeStructureOpenApi): DealFeeStructureType {
    return {
        placementFee: feeStructure.placementFee,
        placementFeeExplanation: feeStructure.placementFeeExplanation,
        managementFee: feeStructure.managementFee,
        carry: feeStructure.carry
    }
}