import React, {FunctionComponent} from "react"
import {IconPropsType} from "./props.type"

const ChartLineIcon: FunctionComponent<IconPropsType> = ({
    width,
    height,
    fillClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
        >
            <path
                className={fillClass}
                d="M21.75 19.5a.75.75 0 0 1-.75.75H3a.75.75 0 0 1-.75-.75v-15A.75.75 0 0 1 3 3.75a.75.75 0 0 1 .75.75v8.847l4.756-4.16a.75.75 0 0 1 .944-.036l5.513 4.135 5.543-4.85a.75.75 0 0 1 .554-.217.75.75 0 0 1 .537.255.75.75 0 0 1 .183.566.75.75 0 0 1-.286.521l-6 5.25a.75.75 0 0 1-.944.036l-5.513-4.133L3.75 15.34v3.41H21a.75.75 0 0 1 .75.75z"
            />
        </svg>
    )
}

export default ChartLineIcon