import React, {FunctionComponent} from "react"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import {DealInterface} from "../deal.interface"
import styles from "./not-yet-published.module.sass"

type DealNotYetPublishedProps = {
    deal: DealInterface
}

const DealNotYetPublished: FunctionComponent<DealNotYetPublishedProps> = ({ deal }) => {
    if (deal.published) {
        return <></>
    }

    return (
        <div className={styles.notYetPublished}>
            <Alert
                type={AlertType.WARNING}
                text={(
                    <>
                        This deal is <strong>not yet published</strong>. You can only see it as ADMINISTRATOR.
                    </>
                )}
            />
        </div>
    )
}

export default DealNotYetPublished