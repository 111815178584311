import React, {FunctionComponent} from "react"
import {InvestorOnboardingType} from "../../onboarding.type"
import Head from "../../../../../tech/head/head.component"
import GeneralDataForm from "./form.component"
import InvestorOnboardingDescription from "../../description/description.component"

type InvestorOnboardingGeneralDataProps = {
    updateOnboarding: (onboarding: InvestorOnboardingType) => void
}

const InvestorOnboardingGeneralData: FunctionComponent<InvestorOnboardingGeneralDataProps> = ({ updateOnboarding }) => {
    return (
        <div>
            <Head title="Personal data"/>
            <InvestorOnboardingDescription content={[
                "Please provide us your name, address and contact details."
            ]}/>
            <GeneralDataForm updateOnboarding={updateOnboarding}/>
        </div>
    )
}

export default InvestorOnboardingGeneralData
