import {DealSortType} from "./sort.type"
import {compareDates, compareStrings} from "../../../tech/sort/sort.util"
import {DealStageOpenApi} from "../../../generated"
import {DealOnboardingDependentWithInvestmentType} from "../onboarding-dependent.type"

export function compareByDealSortType(sortType: DealSortType): (a: DealOnboardingDependentWithInvestmentType, b: DealOnboardingDependentWithInvestmentType) => number {
    switch (sortType) {
        case "CATEGORY":
            return (a: DealOnboardingDependentWithInvestmentType, b: DealOnboardingDependentWithInvestmentType) => {
                const categoryA = a.deal.categories.length > 0 ? a.deal.categories[0] : undefined
                const categoryB = b.deal.categories.length > 0 ? b.deal.categories[0] : undefined
                return compareStrings(categoryA?.name, categoryB?.name, "ASCENDING")
            }
        case "GEOGRAPHY":
            return (
                a: DealOnboardingDependentWithInvestmentType,
                b: DealOnboardingDependentWithInvestmentType
            ) => compareStrings(a.deal.geography?.name, b.deal.geography?.name, "ASCENDING")
        case "FINAL_DEADLINE":
            return (
                a: DealOnboardingDependentWithInvestmentType,
                b: DealOnboardingDependentWithInvestmentType
            ) => compareDates(a.deal._finalDeadline, b.deal._finalDeadline, "ASCENDING")
        case "NAME":
            return (
                a: DealOnboardingDependentWithInvestmentType,
                b: DealOnboardingDependentWithInvestmentType
            ) => compareStrings(a.deal.name, b.deal.name, "ASCENDING")
        case "STAGE":
            return (
                a: DealOnboardingDependentWithInvestmentType,
                b: DealOnboardingDependentWithInvestmentType
            ) => compareStages(a.deal._stage, b.deal._stage)
    }
}

function compareStages(a: DealStageOpenApi, b: DealStageOpenApi): number {
    if (getStageOrder(a) < getStageOrder(b)) return -1
    if (getStageOrder(a) > getStageOrder(b)) return 1
    return 0
}

function getStageOrder(stage: DealStageOpenApi): number {
    switch (stage) {
        case "SEED":
        case "EARLY_STAGE":
            return 1
        case "FUND":
            return 2
        case "SERIES_A":
            return 3
        case "GROWTH_STAGE":
        case "SERIES_B":
        case "SERIES_C":
            return 4
        case "LATE_STAGE":
            return 5
        case "PRE_IPO":
            return 6
        case "NONE":
            return 99
    }
}

export function getStringRepresentation(sortType: DealSortType): string {
    switch (sortType) {
        case "CATEGORY":
            return "Category"
        case "GEOGRAPHY":
            return "Geography"
        case "FINAL_DEADLINE":
            return "Final Deadline"
        case "NAME":
            return "Name"
        case "STAGE":
            return "Stage"
    }
}