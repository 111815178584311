import React, {FunctionComponent} from "react"

const PoliticallyExposedPersonExplanation: FunctionComponent = () => {
    return (
        <div>
            MAS defines a <strong>Politically Exposed Person</strong> as someone who:
            <ol type="i">
                <li>a politically exposed person (e.g. a person who is or has been entrusted with any prominent public function in Singapore, a country, or territory outside Singapore, or by an international organisation) presently; or</li>
                <li>a politically exposed person (e.g. a person who has been entrusted with any prominent public function in Singapore, a country, or territory outside Singapore, or by an international organisation) who has stepped down from his prominent public function; or</li>
                <li>an immediate family member or a close associate of a politically exposed person or a politically exposed person who has stepped down.</li>
            </ol>
        </div>
    )
}

export default PoliticallyExposedPersonExplanation