import {InvestorOnboardingOpenApi} from "../../../generated"
import {InvestorOnboardingType} from "./onboarding.type"
import {mapOpenApiToInvestorWithDetails} from "../investor.mapper"
import {mapOpenApiToInvestorOnboardingStageType} from "./stage/stage.mapper"
import {mapOpenApiToSyndicate} from "../../syndicate/syndicate.mapper"

export function mapOpenApiToInvestorOnboarding(investorOnboarding: InvestorOnboardingOpenApi): InvestorOnboardingType {
    return {
        blocked: investorOnboarding.blocked,
        investorWithDetails: investorOnboarding.investorWithDetails
            && mapOpenApiToInvestorWithDetails(investorOnboarding.investorWithDetails),
        stage: mapOpenApiToInvestorOnboardingStageType(investorOnboarding.stage),
        syndicatesWithPendingInvitation: investorOnboarding.syndicatesWithPendingInvitation.map(mapOpenApiToSyndicate)
    }
}