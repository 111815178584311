import {FileType} from "./file.type"
import {FFileOpenApi} from "../../generated"

export function mapOpenApiToFile(file: FFileOpenApi): FileType {
    return {
        id: file.id,
        sequentialId: file.sequentialId,
        path: file.path,
        type: file.type,
        mimeType: file.mimeType,
        url: file.url
    }
}

export function mapFileToOpenApi(file: FileType): FFileOpenApi {
    return {
        id: file.id,
        sequentialId: file.sequentialId,
        path: file.path,
        type: file.type,
        mimeType: file.mimeType
    }
}