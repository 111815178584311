/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CompanyEmbeddableOpenApi } from './CompanyEmbeddableOpenApi';
import {
    CompanyEmbeddableOpenApiFromJSON,
    CompanyEmbeddableOpenApiFromJSONTyped,
    CompanyEmbeddableOpenApiToJSON,
} from './CompanyEmbeddableOpenApi';
import type { CurrencyOpenApi } from './CurrencyOpenApi';
import {
    CurrencyOpenApiFromJSON,
    CurrencyOpenApiFromJSONTyped,
    CurrencyOpenApiToJSON,
} from './CurrencyOpenApi';
import type { DealCategoryOpenApi } from './DealCategoryOpenApi';
import {
    DealCategoryOpenApiFromJSON,
    DealCategoryOpenApiFromJSONTyped,
    DealCategoryOpenApiToJSON,
} from './DealCategoryOpenApi';
import type { DealDocumentOpenApi } from './DealDocumentOpenApi';
import {
    DealDocumentOpenApiFromJSON,
    DealDocumentOpenApiFromJSONTyped,
    DealDocumentOpenApiToJSON,
} from './DealDocumentOpenApi';
import type { DealEventOpenApi } from './DealEventOpenApi';
import {
    DealEventOpenApiFromJSON,
    DealEventOpenApiFromJSONTyped,
    DealEventOpenApiToJSON,
} from './DealEventOpenApi';
import type { DealExitTypeOpenApi } from './DealExitTypeOpenApi';
import {
    DealExitTypeOpenApiFromJSON,
    DealExitTypeOpenApiFromJSONTyped,
    DealExitTypeOpenApiToJSON,
} from './DealExitTypeOpenApi';
import type { DealFeeStructureOpenApi } from './DealFeeStructureOpenApi';
import {
    DealFeeStructureOpenApiFromJSON,
    DealFeeStructureOpenApiFromJSONTyped,
    DealFeeStructureOpenApiToJSON,
} from './DealFeeStructureOpenApi';
import type { DealGeographyOpenApi } from './DealGeographyOpenApi';
import {
    DealGeographyOpenApiFromJSON,
    DealGeographyOpenApiFromJSONTyped,
    DealGeographyOpenApiToJSON,
} from './DealGeographyOpenApi';
import type { DealGroupOpenApi } from './DealGroupOpenApi';
import {
    DealGroupOpenApiFromJSON,
    DealGroupOpenApiFromJSONTyped,
    DealGroupOpenApiToJSON,
} from './DealGroupOpenApi';
import type { DealIndustryOpenApi } from './DealIndustryOpenApi';
import {
    DealIndustryOpenApiFromJSON,
    DealIndustryOpenApiFromJSONTyped,
    DealIndustryOpenApiToJSON,
} from './DealIndustryOpenApi';
import type { DealInvestorGroupOpenApi } from './DealInvestorGroupOpenApi';
import {
    DealInvestorGroupOpenApiFromJSON,
    DealInvestorGroupOpenApiFromJSONTyped,
    DealInvestorGroupOpenApiToJSON,
} from './DealInvestorGroupOpenApi';
import type { DealManualReconciliationBankAccountOpenApi } from './DealManualReconciliationBankAccountOpenApi';
import {
    DealManualReconciliationBankAccountOpenApiFromJSON,
    DealManualReconciliationBankAccountOpenApiFromJSONTyped,
    DealManualReconciliationBankAccountOpenApiToJSON,
} from './DealManualReconciliationBankAccountOpenApi';
import type { DealOutcomeOpenApi } from './DealOutcomeOpenApi';
import {
    DealOutcomeOpenApiFromJSON,
    DealOutcomeOpenApiFromJSONTyped,
    DealOutcomeOpenApiToJSON,
} from './DealOutcomeOpenApi';
import type { DealPaymentInstructionsTriggerOpenApi } from './DealPaymentInstructionsTriggerOpenApi';
import {
    DealPaymentInstructionsTriggerOpenApiFromJSON,
    DealPaymentInstructionsTriggerOpenApiFromJSONTyped,
    DealPaymentInstructionsTriggerOpenApiToJSON,
} from './DealPaymentInstructionsTriggerOpenApi';
import type { DealPaymentProcessingTypeOpenApi } from './DealPaymentProcessingTypeOpenApi';
import {
    DealPaymentProcessingTypeOpenApiFromJSON,
    DealPaymentProcessingTypeOpenApiFromJSONTyped,
    DealPaymentProcessingTypeOpenApiToJSON,
} from './DealPaymentProcessingTypeOpenApi';
import type { DealPubliclyAvailableDataPointOpenApi } from './DealPubliclyAvailableDataPointOpenApi';
import {
    DealPubliclyAvailableDataPointOpenApiFromJSON,
    DealPubliclyAvailableDataPointOpenApiFromJSONTyped,
    DealPubliclyAvailableDataPointOpenApiToJSON,
} from './DealPubliclyAvailableDataPointOpenApi';
import type { DealSlideDeckOpenApi } from './DealSlideDeckOpenApi';
import {
    DealSlideDeckOpenApiFromJSON,
    DealSlideDeckOpenApiFromJSONTyped,
    DealSlideDeckOpenApiToJSON,
} from './DealSlideDeckOpenApi';
import type { DealStageOpenApi } from './DealStageOpenApi';
import {
    DealStageOpenApiFromJSON,
    DealStageOpenApiFromJSONTyped,
    DealStageOpenApiToJSON,
} from './DealStageOpenApi';
import type { DealTileOpenApi } from './DealTileOpenApi';
import {
    DealTileOpenApiFromJSON,
    DealTileOpenApiFromJSONTyped,
    DealTileOpenApiToJSON,
} from './DealTileOpenApi';
import type { DealWaitingStateOpenApi } from './DealWaitingStateOpenApi';
import {
    DealWaitingStateOpenApiFromJSON,
    DealWaitingStateOpenApiFromJSONTyped,
    DealWaitingStateOpenApiToJSON,
} from './DealWaitingStateOpenApi';
import type { ImageOpenApi } from './ImageOpenApi';
import {
    ImageOpenApiFromJSON,
    ImageOpenApiFromJSONTyped,
    ImageOpenApiToJSON,
} from './ImageOpenApi';
import type { ShareClassOpenApi } from './ShareClassOpenApi';
import {
    ShareClassOpenApiFromJSON,
    ShareClassOpenApiFromJSONTyped,
    ShareClassOpenApiToJSON,
} from './ShareClassOpenApi';
import type { SinglePurposeVehicleOpenApi } from './SinglePurposeVehicleOpenApi';
import {
    SinglePurposeVehicleOpenApiFromJSON,
    SinglePurposeVehicleOpenApiFromJSONTyped,
    SinglePurposeVehicleOpenApiToJSON,
} from './SinglePurposeVehicleOpenApi';

/**
 * 
 * @export
 * @interface DealOpenApi
 */
export interface DealOpenApi {
    /**
     * 
     * @type {string}
     * @memberof DealOpenApi
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof DealOpenApi
     */
    sequentialId?: number;
    /**
     * 
     * @type {Date}
     * @memberof DealOpenApi
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DealOpenApi
     */
    modified?: Date;
    /**
     * 
     * @type {string}
     * @memberof DealOpenApi
     */
    nameIncompleteOnboarding: string;
    /**
     * 
     * @type {string}
     * @memberof DealOpenApi
     */
    descriptionIncompleteOnboarding: string;
    /**
     * 
     * @type {CompanyEmbeddableOpenApi}
     * @memberof DealOpenApi
     */
    company?: CompanyEmbeddableOpenApi;
    /**
     * 
     * @type {string}
     * @memberof DealOpenApi
     */
    companyId?: string;
    /**
     * 
     * @type {Array<DealCategoryOpenApi>}
     * @memberof DealOpenApi
     */
    categories: Array<DealCategoryOpenApi>;
    /**
     * 
     * @type {DealGeographyOpenApi}
     * @memberof DealOpenApi
     */
    geography?: DealGeographyOpenApi;
    /**
     * 
     * @type {ImageOpenApi}
     * @memberof DealOpenApi
     */
    image?: ImageOpenApi;
    /**
     * 
     * @type {DealStageOpenApi}
     * @memberof DealOpenApi
     */
    stage: DealStageOpenApi;
    /**
     * 
     * @type {DealOutcomeOpenApi}
     * @memberof DealOpenApi
     */
    outcome: DealOutcomeOpenApi;
    /**
     * 
     * @type {DealWaitingStateOpenApi}
     * @memberof DealOpenApi
     */
    waitingState?: DealWaitingStateOpenApi;
    /**
     * 
     * @type {DealIndustryOpenApi}
     * @memberof DealOpenApi
     */
    industry: DealIndustryOpenApi;
    /**
     * 
     * @type {Date}
     * @memberof DealOpenApi
     */
    finalDeadline: Date;
    /**
     * 
     * @type {boolean}
     * @memberof DealOpenApi
     */
    exclusive: boolean;
    /**
     * 
     * @type {Array<DealGroupOpenApi>}
     * @memberof DealOpenApi
     */
    dealGroups: Array<DealGroupOpenApi>;
    /**
     * 
     * @type {Array<DealInvestorGroupOpenApi>}
     * @memberof DealOpenApi
     */
    investorGroups?: Array<DealInvestorGroupOpenApi>;
    /**
     * 
     * @type {Array<DealManualReconciliationBankAccountOpenApi>}
     * @memberof DealOpenApi
     */
    manualReconciliationBankAccounts: Array<DealManualReconciliationBankAccountOpenApi>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DealOpenApi
     */
    documentTemplateIds: Array<number>;
    /**
     * 
     * @type {DealPaymentProcessingTypeOpenApi}
     * @memberof DealOpenApi
     */
    paymentProcessingType: DealPaymentProcessingTypeOpenApi;
    /**
     * 
     * @type {DealPaymentInstructionsTriggerOpenApi}
     * @memberof DealOpenApi
     */
    paymentInstructionsTrigger: DealPaymentInstructionsTriggerOpenApi;
    /**
     * 
     * @type {Date}
     * @memberof DealOpenApi
     */
    paymentInstructionsManuallyTriggered?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DealOpenApi
     */
    exited?: Date;
    /**
     * 
     * @type {DealExitTypeOpenApi}
     * @memberof DealOpenApi
     */
    exitType?: DealExitTypeOpenApi;
    /**
     * 
     * @type {boolean}
     * @memberof DealOpenApi
     */
    published: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealOpenApi
     */
    imported: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealOpenApi
     */
    publiclyAvailable: boolean;
    /**
     * 
     * @type {Array<DealPubliclyAvailableDataPointOpenApi>}
     * @memberof DealOpenApi
     */
    publiclyAvailableDataPoints?: Array<DealPubliclyAvailableDataPointOpenApi>;
    /**
     * 
     * @type {boolean}
     * @memberof DealOpenApi
     */
    showCompletelyWithoutOnboarding: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DealOpenApi
     */
    favourite: boolean;
    /**
     * 
     * @type {string}
     * @memberof DealOpenApi
     */
    objectType: string;
    /**
     * 
     * @type {string}
     * @memberof DealOpenApi
     */
    nameCompleteOnboarding: string;
    /**
     * 
     * @type {string}
     * @memberof DealOpenApi
     */
    descriptionCompleteOnboarding: string;
    /**
     * 
     * @type {number}
     * @memberof DealOpenApi
     */
    minTarget: number;
    /**
     * 
     * @type {number}
     * @memberof DealOpenApi
     */
    maxTarget: number;
    /**
     * 
     * @type {number}
     * @memberof DealOpenApi
     */
    minTicket: number;
    /**
     * 
     * @type {CurrencyOpenApi}
     * @memberof DealOpenApi
     */
    currency: CurrencyOpenApi;
    /**
     * 
     * @type {number}
     * @memberof DealOpenApi
     */
    investedAmount: number;
    /**
     * 
     * @type {ImageOpenApi}
     * @memberof DealOpenApi
     */
    logo?: ImageOpenApi;
    /**
     * 
     * @type {Array<DealTileOpenApi>}
     * @memberof DealOpenApi
     */
    tiles: Array<DealTileOpenApi>;
    /**
     * 
     * @type {DealSlideDeckOpenApi}
     * @memberof DealOpenApi
     */
    slideDeck?: DealSlideDeckOpenApi;
    /**
     * 
     * @type {string}
     * @memberof DealOpenApi
     */
    videoYoutubeCode?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DealOpenApi
     */
    investors: Array<string>;
    /**
     * 
     * @type {Array<DealDocumentOpenApi>}
     * @memberof DealOpenApi
     */
    documents: Array<DealDocumentOpenApi>;
    /**
     * 
     * @type {DealEventOpenApi}
     * @memberof DealOpenApi
     */
    event?: DealEventOpenApi;
    /**
     * 
     * @type {DealFeeStructureOpenApi}
     * @memberof DealOpenApi
     */
    feeStructure: DealFeeStructureOpenApi;
    /**
     * 
     * @type {ShareClassOpenApi}
     * @memberof DealOpenApi
     */
    shareClassForNameInDealContract: ShareClassOpenApi;
    /**
     * 
     * @type {SinglePurposeVehicleOpenApi}
     * @memberof DealOpenApi
     */
    singlePurposeVehicle: SinglePurposeVehicleOpenApi;
    /**
     * 
     * @type {Array<string>}
     * @memberof DealOpenApi
     */
    termsTags: Array<string>;
}

/**
 * Check if a given object implements the DealOpenApi interface.
 */
export function instanceOfDealOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "nameIncompleteOnboarding" in value;
    isInstance = isInstance && "descriptionIncompleteOnboarding" in value;
    isInstance = isInstance && "categories" in value;
    isInstance = isInstance && "stage" in value;
    isInstance = isInstance && "outcome" in value;
    isInstance = isInstance && "industry" in value;
    isInstance = isInstance && "finalDeadline" in value;
    isInstance = isInstance && "exclusive" in value;
    isInstance = isInstance && "dealGroups" in value;
    isInstance = isInstance && "manualReconciliationBankAccounts" in value;
    isInstance = isInstance && "documentTemplateIds" in value;
    isInstance = isInstance && "paymentProcessingType" in value;
    isInstance = isInstance && "paymentInstructionsTrigger" in value;
    isInstance = isInstance && "published" in value;
    isInstance = isInstance && "imported" in value;
    isInstance = isInstance && "publiclyAvailable" in value;
    isInstance = isInstance && "showCompletelyWithoutOnboarding" in value;
    isInstance = isInstance && "favourite" in value;
    isInstance = isInstance && "objectType" in value;
    isInstance = isInstance && "nameCompleteOnboarding" in value;
    isInstance = isInstance && "descriptionCompleteOnboarding" in value;
    isInstance = isInstance && "minTarget" in value;
    isInstance = isInstance && "maxTarget" in value;
    isInstance = isInstance && "minTicket" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "investedAmount" in value;
    isInstance = isInstance && "tiles" in value;
    isInstance = isInstance && "investors" in value;
    isInstance = isInstance && "documents" in value;
    isInstance = isInstance && "feeStructure" in value;
    isInstance = isInstance && "shareClassForNameInDealContract" in value;
    isInstance = isInstance && "singlePurposeVehicle" in value;
    isInstance = isInstance && "termsTags" in value;

    return isInstance;
}

export function DealOpenApiFromJSON(json: any): DealOpenApi {
    return DealOpenApiFromJSONTyped(json, false);
}

export function DealOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'sequentialId': !exists(json, 'sequentialId') ? undefined : json['sequentialId'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'nameIncompleteOnboarding': json['nameIncompleteOnboarding'],
        'descriptionIncompleteOnboarding': json['descriptionIncompleteOnboarding'],
        'company': !exists(json, 'company') ? undefined : CompanyEmbeddableOpenApiFromJSON(json['company']),
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'categories': ((json['categories'] as Array<any>).map(DealCategoryOpenApiFromJSON)),
        'geography': !exists(json, 'geography') ? undefined : DealGeographyOpenApiFromJSON(json['geography']),
        'image': !exists(json, 'image') ? undefined : ImageOpenApiFromJSON(json['image']),
        'stage': DealStageOpenApiFromJSON(json['stage']),
        'outcome': DealOutcomeOpenApiFromJSON(json['outcome']),
        'waitingState': !exists(json, 'waitingState') ? undefined : DealWaitingStateOpenApiFromJSON(json['waitingState']),
        'industry': DealIndustryOpenApiFromJSON(json['industry']),
        'finalDeadline': (new Date(json['finalDeadline'])),
        'exclusive': json['exclusive'],
        'dealGroups': ((json['dealGroups'] as Array<any>).map(DealGroupOpenApiFromJSON)),
        'investorGroups': !exists(json, 'investorGroups') ? undefined : ((json['investorGroups'] as Array<any>).map(DealInvestorGroupOpenApiFromJSON)),
        'manualReconciliationBankAccounts': ((json['manualReconciliationBankAccounts'] as Array<any>).map(DealManualReconciliationBankAccountOpenApiFromJSON)),
        'documentTemplateIds': json['documentTemplateIds'],
        'paymentProcessingType': DealPaymentProcessingTypeOpenApiFromJSON(json['paymentProcessingType']),
        'paymentInstructionsTrigger': DealPaymentInstructionsTriggerOpenApiFromJSON(json['paymentInstructionsTrigger']),
        'paymentInstructionsManuallyTriggered': !exists(json, 'paymentInstructionsManuallyTriggered') ? undefined : (new Date(json['paymentInstructionsManuallyTriggered'])),
        'exited': !exists(json, 'exited') ? undefined : (new Date(json['exited'])),
        'exitType': !exists(json, 'exitType') ? undefined : DealExitTypeOpenApiFromJSON(json['exitType']),
        'published': json['published'],
        'imported': json['imported'],
        'publiclyAvailable': json['publiclyAvailable'],
        'publiclyAvailableDataPoints': !exists(json, 'publiclyAvailableDataPoints') ? undefined : ((json['publiclyAvailableDataPoints'] as Array<any>).map(DealPubliclyAvailableDataPointOpenApiFromJSON)),
        'showCompletelyWithoutOnboarding': json['showCompletelyWithoutOnboarding'],
        'favourite': json['favourite'],
        'objectType': json['objectType'],
        'nameCompleteOnboarding': json['nameCompleteOnboarding'],
        'descriptionCompleteOnboarding': json['descriptionCompleteOnboarding'],
        'minTarget': json['minTarget'],
        'maxTarget': json['maxTarget'],
        'minTicket': json['minTicket'],
        'currency': CurrencyOpenApiFromJSON(json['currency']),
        'investedAmount': json['investedAmount'],
        'logo': !exists(json, 'logo') ? undefined : ImageOpenApiFromJSON(json['logo']),
        'tiles': ((json['tiles'] as Array<any>).map(DealTileOpenApiFromJSON)),
        'slideDeck': !exists(json, 'slideDeck') ? undefined : DealSlideDeckOpenApiFromJSON(json['slideDeck']),
        'videoYoutubeCode': !exists(json, 'videoYoutubeCode') ? undefined : json['videoYoutubeCode'],
        'investors': json['investors'],
        'documents': ((json['documents'] as Array<any>).map(DealDocumentOpenApiFromJSON)),
        'event': !exists(json, 'event') ? undefined : DealEventOpenApiFromJSON(json['event']),
        'feeStructure': DealFeeStructureOpenApiFromJSON(json['feeStructure']),
        'shareClassForNameInDealContract': ShareClassOpenApiFromJSON(json['shareClassForNameInDealContract']),
        'singlePurposeVehicle': SinglePurposeVehicleOpenApiFromJSON(json['singlePurposeVehicle']),
        'termsTags': json['termsTags'],
    };
}

export function DealOpenApiToJSON(value?: DealOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sequentialId': value.sequentialId,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'modified': value.modified === undefined ? undefined : (value.modified.toISOString()),
        'nameIncompleteOnboarding': value.nameIncompleteOnboarding,
        'descriptionIncompleteOnboarding': value.descriptionIncompleteOnboarding,
        'company': CompanyEmbeddableOpenApiToJSON(value.company),
        'companyId': value.companyId,
        'categories': ((value.categories as Array<any>).map(DealCategoryOpenApiToJSON)),
        'geography': DealGeographyOpenApiToJSON(value.geography),
        'image': ImageOpenApiToJSON(value.image),
        'stage': DealStageOpenApiToJSON(value.stage),
        'outcome': DealOutcomeOpenApiToJSON(value.outcome),
        'waitingState': DealWaitingStateOpenApiToJSON(value.waitingState),
        'industry': DealIndustryOpenApiToJSON(value.industry),
        'finalDeadline': (value.finalDeadline.toISOString().substr(0,10)),
        'exclusive': value.exclusive,
        'dealGroups': ((value.dealGroups as Array<any>).map(DealGroupOpenApiToJSON)),
        'investorGroups': value.investorGroups === undefined ? undefined : ((value.investorGroups as Array<any>).map(DealInvestorGroupOpenApiToJSON)),
        'manualReconciliationBankAccounts': ((value.manualReconciliationBankAccounts as Array<any>).map(DealManualReconciliationBankAccountOpenApiToJSON)),
        'documentTemplateIds': value.documentTemplateIds,
        'paymentProcessingType': DealPaymentProcessingTypeOpenApiToJSON(value.paymentProcessingType),
        'paymentInstructionsTrigger': DealPaymentInstructionsTriggerOpenApiToJSON(value.paymentInstructionsTrigger),
        'paymentInstructionsManuallyTriggered': value.paymentInstructionsManuallyTriggered === undefined ? undefined : (value.paymentInstructionsManuallyTriggered.toISOString()),
        'exited': value.exited === undefined ? undefined : (value.exited.toISOString().substr(0,10)),
        'exitType': DealExitTypeOpenApiToJSON(value.exitType),
        'published': value.published,
        'imported': value.imported,
        'publiclyAvailable': value.publiclyAvailable,
        'publiclyAvailableDataPoints': value.publiclyAvailableDataPoints === undefined ? undefined : ((value.publiclyAvailableDataPoints as Array<any>).map(DealPubliclyAvailableDataPointOpenApiToJSON)),
        'showCompletelyWithoutOnboarding': value.showCompletelyWithoutOnboarding,
        'favourite': value.favourite,
        'objectType': value.objectType,
        'nameCompleteOnboarding': value.nameCompleteOnboarding,
        'descriptionCompleteOnboarding': value.descriptionCompleteOnboarding,
        'minTarget': value.minTarget,
        'maxTarget': value.maxTarget,
        'minTicket': value.minTicket,
        'currency': CurrencyOpenApiToJSON(value.currency),
        'investedAmount': value.investedAmount,
        'logo': ImageOpenApiToJSON(value.logo),
        'tiles': ((value.tiles as Array<any>).map(DealTileOpenApiToJSON)),
        'slideDeck': DealSlideDeckOpenApiToJSON(value.slideDeck),
        'videoYoutubeCode': value.videoYoutubeCode,
        'investors': value.investors,
        'documents': ((value.documents as Array<any>).map(DealDocumentOpenApiToJSON)),
        'event': DealEventOpenApiToJSON(value.event),
        'feeStructure': DealFeeStructureOpenApiToJSON(value.feeStructure),
        'shareClassForNameInDealContract': ShareClassOpenApiToJSON(value.shareClassForNameInDealContract),
        'singlePurposeVehicle': SinglePurposeVehicleOpenApiToJSON(value.singlePurposeVehicle),
        'termsTags': value.termsTags,
    };
}

