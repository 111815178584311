import React, {FunctionComponent} from "react"
import {DealDocumentType} from "../../document/document.type"
import DealDocuments from "../../document/documents.component"
import {DealOnboardingDependentType} from "../../onboarding-dependent.type"
import {DealType} from "../../deal.type"
import {InvestmentType} from "../../../investment/investment.type"
import {DocumentType} from "../../../document/document.type"
import styles from "./documents.module.sass"

type DealDocumentsTileProps = {
    deal: DealOnboardingDependentType
    investment?: InvestmentType
}

const DealDocumentsTile: FunctionComponent<DealDocumentsTileProps> = ({
    deal,
    investment
}) => {
    if (!(deal instanceof DealType)) return <></>

    let documents: (DealDocumentType | DocumentType)[] = [
        ...deal.documents,
        ...(investment?.documents || [])
    ]

    if (!documents || documents.length < 1) return <></>
    return (
        <div className={styles.documents}>
            <h4>Deal Documents</h4>
            <DealDocuments documents={documents}/>
        </div>
    )
}

export default DealDocumentsTile