import React, {ChangeEvent, FunctionComponent} from "react"
import DealInvestmentInitializationAmountFees from "./fees/fees.component"
import {DealType} from "../../deal.type"
import initializationStyles from "../initialization.module.sass"
import XIcon from "../../../../tech/icon/x.component"
import {formatAmountWithCurrency} from "../../../../tech/format/amount.util"
import styles from "./amount.module.sass"

type DealInvestmentInitializationAmountProps = {
    deal: DealType
    amount: number
    updateAmount: (amount: number) => void
    amountErrors: string[]
    updateAmountErrors: (newAmountErrors: string[]) => void
}

const DealInvestmentInitializationAmount: FunctionComponent<DealInvestmentInitializationAmountProps> = ({
    deal,
    amount,
    updateAmount,
    amountErrors,
    updateAmountErrors
}) => {
    const amountStep = 1000

    const validateAndSet = (e: ChangeEvent<HTMLInputElement>) => validateAndSetInternal(parseInt(e.target.value))

    const validateAndSetInternal = (val: number) => {
        const newErrors: string[] = []
        if (val < deal.minTicket) {
            newErrors.push(`Amount must not be smaller than minimum ticket size of ${formatAmountWithCurrency(deal.minTicket, deal._currency, 0)}.`)
        }
        if (val % amountStep !== 0) {
            newErrors.push(`Amount must be a multiple of ${amountStep}.`)
        }
        if (val > evaluateMaximum(deal)) {
            newErrors.push(`Amount must not be bigger than ${formatAmountWithCurrency(evaluateMaximum(deal), deal._currency, 0)}.`)
        }
        updateAmount(val)
        updateAmountErrors(newErrors)
    }

    return (
        <div className={styles.amount}>
            <div className={initializationStyles.title}>
                How much do you want to invest?
            </div>
            <div className={styles.input}>
                <div className={styles.currency}>
                    <div>
                        {deal.currency}
                    </div>
                </div>
                <div className={styles.number}>
                    <input
                        type="number"
                        step={amountStep}
                        value={amount}
                        onChange={validateAndSet}
                        onBlur={validateAndSet}
                    />
                </div>
                {amount !== undefined && (
                    <div
                        className={styles.clear}
                        onClick={() => validateAndSetInternal(deal.minTicket)}
                    >
                        <div>
                            <XIcon
                                width={16}
                                height={16}
                                fillClass="fill-icon-theme"
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.minTicket}>
                <small>Minimum investment of {formatAmountWithCurrency(deal.minTicket, deal._currency, 0)}</small><br/>
            </div>
            {amountErrors.length > 0 && (
                <ul className={styles.errors}>
                    {amountErrors.map((error, index) => (
                        <li key={`error-${index}`}>
                            {error}
                        </li>
                    ))}
                </ul>
            )}
            <DealInvestmentInitializationAmountFees
                amount={amount}
                deal={deal}
            />
        </div>
    )
}

export default DealInvestmentInitializationAmount

function evaluateMaximum(deal: DealType): number {
    return deal._maxTarget
        ? deal._maxTarget - deal._investedAmount
        : deal._minTarget
}