import React, {FunctionComponent, useCallback} from "react"
import {useDropzone} from "react-dropzone"
import styles from "./upload.module.sass"

type FileUploadDesktopUploadProps = {
    label: string
    uploadFile: (file: File) => void
}

const FileUploadDesktopUpload: FunctionComponent<FileUploadDesktopUploadProps> = ({
    label,
    uploadFile
}) => {
    const onDropAccepted = useCallback((acceptedFiles: File[]) => {
        uploadFile(acceptedFiles[0])
    }, [uploadFile])

    const id = Math.floor(Math.random() * 99999) + "-" + label.replaceAll(" ", "_")
    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            'application/pdf': ['.pdf'],
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png'],
        },
        onDropAccepted,
        multiple: false
    })

    return (
        <div {...getRootProps({className: 'dropzone'})}>
            <div className={styles.text}>
                <div>
                    Drag &amp; Drop
                </div>
                <div>
                    your files here or browse
                </div>
                <div>
                    JPG, PDF, PNG up to 20MB
                </div>
            </div>
            <input
                id={id}
                type="file"
                className="sr-only"
                onClick={(e) => e.preventDefault()}
                {...getInputProps()}
            />
        </div>
    )
}
export default FileUploadDesktopUpload