import {SyndicateApiClient} from "../../generated"
import {InvestorOnboardingType} from "../investor/onboarding/onboarding.type"
import {mapOpenApiToInvestorOnboarding} from "../investor/onboarding/onboarding.mapper"

export class SyndicateApi {

    constructor(private apiClient: SyndicateApiClient) {
    }

    public async join(id: string): Promise<InvestorOnboardingType> {
        const res = await this.apiClient.syndicateJoin({ syndicateId: id })
        return mapOpenApiToInvestorOnboarding(res)
    }

}