import React, {FunctionComponent, useEffect, useRef, useState} from "react"
import {DealInterface} from "../../deal.interface"
import styles from "./video.module.sass"

type DealAboutProps = {
    deal: DealInterface
}

const DealVideo: FunctionComponent<DealAboutProps> = ({ deal }) => {
    const [width, setWidth] = useState<number>()
    const widthRef = useRef<HTMLDivElement>(null)

    function evaluateWidth() {
        if (widthRef.current) {
            setWidth(widthRef.current.getBoundingClientRect().width)
        }
    }

    useEffect(() => {
        evaluateWidth()
        window.addEventListener("resize", evaluateWidth)
        return () => window.removeEventListener("resize", evaluateWidth)
    }, [])

    return (
        <>
            <div ref={widthRef}/>
            {deal.videoYoutubeCode && width && (
                <div className={styles.video}>
                    <iframe
                        width={width}
                        height={width * 315 / 560}
                        title={`${deal.name} Video`}
                        src="https://www.youtube.com/embed/ODgcerIngUw?si=eutY2vO5SJ6ISEP6"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                    />
                </div>
            )}
        </>
    )
}

export default DealVideo