import React, {FunctionComponent, useEffect, useState} from "react"
import {DealDocumentType} from "./document.type"
import DownloadIcon from "../../../tech/icon/download.component"
import FileIcon from "../../../tech/icon/file.component"
import {DocumentType} from "../../document/document.type"
import styles from "./document.module.sass"
import LoadingCircle from "../../../tech/loading/circle/circle.component"
import {useApp} from "../../../tech/app/context/app.context"

type DealDocumentProps = {
    document: DealDocumentType | DocumentType
}

const DealDocument: FunctionComponent<DealDocumentProps> = ({ document }) => {
    const app = useApp()
    const [url, setUrl] = useState<string | undefined>(document._type === "DEAL_DOCUMENT" ? document.file.url : undefined)

    useEffect(() => {
        async function fetchUrl() {
            if (document._type === "DOCUMENT" && document.file) {
                setUrl(await app.fetchClient.fileApi.getDownloadLink(document.file!.id!))
            }
        }
        fetchUrl()
    }, [app.fetchClient, document])

    function onClick() {
        if (url) {
            window.open(url, "_blank")
        }
    }

    if (!document.file) return <></>
    return (
        <div
            className={`${styles.document} ${url ? styles.clickable : ""}`}
            onClick={onClick}
        >
            <div className={styles.icon}>
                <FileIcon
                    width={24}
                    height={24}
                    strokeClass="stroke-icon-theme-light"
                />
            </div>
            <div className={styles.content}>
                <h3>{getTitle(document)}</h3>
                <div>
                    {getDescription(document)}
                </div>
            </div>
            <div className={styles.download}>
                {url ? (
                    <DownloadIcon
                        width={24}
                        height={24}
                        strokeClass="stroke-icon-theme-light"
                    />
                ) : (
                    <LoadingCircle
                        width={18}
                        height={18}
                    />
                )}
            </div>
        </div>
    )
}

export default DealDocument

function getTitle(document: DealDocumentType | DocumentType) {
    if (document._type === "DOCUMENT") {
        return document.name.startsWith("NonPublic: ")
            ? document.name.replace("NonPublic: ", "")
            : document.name
    }
    switch (document.type) {
        case "INVESTMENT_TERMS":
            return "Investment Terms"
        case "SPV_CONSTITUTION":
            return "SPV Constitution Document"
        case "OTHER":
            return document.title
    }
}

function getDescription(document: DealDocumentType | DocumentType) {
    if (document._type === "DOCUMENT") {
        return undefined
    }
    switch (document.type) {
        case "INVESTMENT_TERMS":
            return "These Investment Terms form part of the Constitution of the Company."
        case "SPV_CONSTITUTION":
            return "This document serves as the Constitution of the Special Purpose Vehicle (SPV)."
        case "OTHER":
            return document.description
    }
}