import React, {FunctionComponent} from "react"
import {IconPropsStrokeType} from "./props.type"

const FileIcon: FunctionComponent<IconPropsStrokeType> = ({
    width,
    height,
    strokeClass
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            strokeWidth="2"
            strokeLinejoin="round"
            viewBox="0 0 25 24"
            className={strokeClass}
        >
            <path d="M7.5 21a2 2 0 0 1-2-2V3h9l5 5v11a2 2 0 0 1-2 2h-10z"/>
            <path d="M13.5 3v6h6"/>
            <path
                d="M9.5 13h6m-6 4h6"
                strokeLinecap="round"
            />
        </svg>
    )
}

export default FileIcon