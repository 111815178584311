import {DealDocumentOpenApi} from "../../../generated"
import {DealDocumentType} from "./document.type"
import {mapOpenApiToFile} from "../../file/file.mapper"

export function mapOpenApiToDealDocument(document: DealDocumentOpenApi): DealDocumentType {
    return {
        id: document.id,
        file: mapOpenApiToFile(document.file),
        type: document.type,
        title: document.title,
        description: document.description,
        order: document.order,
        _type: "DEAL_DOCUMENT"
    }
}