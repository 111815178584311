import {FunctionComponent} from "react"
import {FormRowType} from "./row.type"
import styles from "./row.module.sass"

type FormRowProps = {
    content: FormRowType
}

const FormRow: FunctionComponent<FormRowProps> = ({ content }) => {
    switch (content.type) {
        case "one":
            return (
                <div className={styles.row}>
                    {content.element}
                </div>
            )
        case "two":
            return (
                <div className={`${styles.rowTwoColumns} ${content.layout === "LEFT_GROW" ? styles.leftGrow : ""} ${content.layout === "RIGHT_GROW" ? styles.rightGrow : ""}`}>
                    <div>
                        {content.firstElement}
                    </div>
                    <div>
                        {content.secondElement}
                    </div>
                </div>
            )
    }
}

export default FormRow