import React, {FunctionComponent, useState} from "react"
import {useNavigate} from "react-router-dom"
import LoadingDots from "../../tech/loading/dots/dots.component"
import {InvestorOnboardingType} from "../investor/onboarding/onboarding.type"
import OnboardingBanner from "../investor/onboarding/banner/banner.component"
import ExclusiveBanner from "./exclusive/exclusive-banner.component"
import {DealSortType} from "./sort/sort.type"
import {FilterType} from "../../tech/filter/filter.type"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import DealList from "./list.component"
import InvestorDashboardSummaryOverview from "../investor/dashboard/summary/overview/overview.component"
import {INVESTOR_DASHBOARD} from "../../paths"
import {useQuery, useQueryClient} from "react-query"
import {QUERY__INVESTOR_DASHBOARD} from "../investor/dashboard/dashboard.query"
import {QUERY__DEALS} from "./deal.query"
import {allDataAvailable, isErroneous, showLoadingAnimation} from "../../tech/query/query.util"
import styles from "./overview.module.sass"
import {QUERY__DEAL_OVERVIEW__DEALS, QUERY__DEAL_OVERVIEW__INVESTOR_DASHBOARD} from "./overview.config"
import {useApp} from "../../tech/app/context/app.context"
import {shouldShowInMenu} from "../../tech/header/menu/menu.util"
import {RouteData} from "../../tech/routing/route.type"
import InvitedToSyndicateBanner from "../syndicate/invited/banner.component"

type DealOverviewProps = {
    onboarding: InvestorOnboardingType
    relevantRoutes: RouteData[]
}

const DealOverview: FunctionComponent<DealOverviewProps> = ({
    onboarding,
    relevantRoutes
}) => {
    const app = useApp()
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const [filterTypes, setFilterTypes] = useState<FilterType[]>([])
    const [sortType, setSortType] = useState<DealSortType>()

    const updateFilterTypes = (updatedFilterTypes: FilterType[]) => setFilterTypes(updatedFilterTypes)
    const updateSortType = (updatedSortType: DealSortType) => setSortType(updatedSortType)

    const resultDeals = useQuery(QUERY__DEALS(
        app.fetchClient,
        queryClient,
        QUERY__DEAL_OVERVIEW__DEALS
    ))
    const resultInvestorDashboard = useQuery(QUERY__INVESTOR_DASHBOARD(app.fetchClient, QUERY__DEAL_OVERVIEW__INVESTOR_DASHBOARD))
    const results = [resultDeals, resultInvestorDashboard]

    return (
        <>
            {showLoadingAnimation(results) && <LoadingDots/>}
            {allDataAvailable(results) && resultDeals.data && resultInvestorDashboard.data && (
                <>
                    <InvitedToSyndicateBanner onboarding={onboarding}/>
                    <OnboardingBanner onboarding={onboarding}/>
                    {shouldShowInMenu("INVESTOR_DASHBOARD", relevantRoutes) && (
                        <div
                            className={styles.summary}
                            onClick={() => navigate(INVESTOR_DASHBOARD)}
                        >
                            <div className={styles.summaryTitle}>
                                Summary
                            </div>
                            <InvestorDashboardSummaryOverview
                                dashboard={resultInvestorDashboard.data}
                                onboarding={onboarding}
                            />
                        </div>
                    )}
                    <ExclusiveBanner exclusiveDeals={resultDeals.data.map(d => d.deal).filter(d => d.exclusive)}/>
                    <DealList
                        dealsWithInvestment={resultDeals.data}
                        title="Available Deals"
                        filterTypes={filterTypes}
                        updateFilterTypes={updateFilterTypes}
                        sortType={sortType}
                        updateSortType={updateSortType}
                        emptyState={{
                            imageSrc: "/empty-state/empty-state-deals.svg",
                            textLine1: resultDeals.data.length > 0
                                ? "This combination of filters yielded no data"
                                : "No deals available",
                            textLine2: resultDeals.data.length > 0
                                ? "Begin your search again"
                                : ""
                        }}
                        onboarding={onboarding}
                    />
                </>
            )}
            {isErroneous(results) && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to load deals."
                />
            )}
        </>
    )
}

export default DealOverview