/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CurrencyOpenApi } from './CurrencyOpenApi';
import {
    CurrencyOpenApiFromJSON,
    CurrencyOpenApiFromJSONTyped,
    CurrencyOpenApiToJSON,
} from './CurrencyOpenApi';
import type { IndividualInvestmentValuationOpenApi } from './IndividualInvestmentValuationOpenApi';
import {
    IndividualInvestmentValuationOpenApiFromJSON,
    IndividualInvestmentValuationOpenApiFromJSONTyped,
    IndividualInvestmentValuationOpenApiToJSON,
} from './IndividualInvestmentValuationOpenApi';

/**
 * 
 * @export
 * @interface IndividualInvestmentOpenApi
 */
export interface IndividualInvestmentOpenApi {
    /**
     * 
     * @type {string}
     * @memberof IndividualInvestmentOpenApi
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualInvestmentOpenApi
     */
    name: string;
    /**
     * 
     * @type {CurrencyOpenApi}
     * @memberof IndividualInvestmentOpenApi
     */
    currency: CurrencyOpenApi;
    /**
     * 
     * @type {number}
     * @memberof IndividualInvestmentOpenApi
     */
    investedAmount: number;
    /**
     * 
     * @type {boolean}
     * @memberof IndividualInvestmentOpenApi
     */
    exited: boolean;
    /**
     * 
     * @type {string}
     * @memberof IndividualInvestmentOpenApi
     */
    investorId?: string;
    /**
     * 
     * @type {Array<IndividualInvestmentValuationOpenApi>}
     * @memberof IndividualInvestmentOpenApi
     */
    valuations: Array<IndividualInvestmentValuationOpenApi>;
    /**
     * 
     * @type {string}
     * @memberof IndividualInvestmentOpenApi
     */
    objectType: string;
}

/**
 * Check if a given object implements the IndividualInvestmentOpenApi interface.
 */
export function instanceOfIndividualInvestmentOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "investedAmount" in value;
    isInstance = isInstance && "exited" in value;
    isInstance = isInstance && "valuations" in value;
    isInstance = isInstance && "objectType" in value;

    return isInstance;
}

export function IndividualInvestmentOpenApiFromJSON(json: any): IndividualInvestmentOpenApi {
    return IndividualInvestmentOpenApiFromJSONTyped(json, false);
}

export function IndividualInvestmentOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): IndividualInvestmentOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'currency': CurrencyOpenApiFromJSON(json['currency']),
        'investedAmount': json['investedAmount'],
        'exited': json['exited'],
        'investorId': !exists(json, 'investorId') ? undefined : json['investorId'],
        'valuations': ((json['valuations'] as Array<any>).map(IndividualInvestmentValuationOpenApiFromJSON)),
        'objectType': json['objectType'],
    };
}

export function IndividualInvestmentOpenApiToJSON(value?: IndividualInvestmentOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'currency': CurrencyOpenApiToJSON(value.currency),
        'investedAmount': value.investedAmount,
        'exited': value.exited,
        'investorId': value.investorId,
        'valuations': ((value.valuations as Array<any>).map(IndividualInvestmentValuationOpenApiToJSON)),
        'objectType': value.objectType,
    };
}

