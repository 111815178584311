import React, {FunctionComponent} from "react"
import FavouriteBookmark from "../../favourite/bookmark.component"
import styles from "./head.module.sass"
import {DealOnboardingDependentType} from "../../onboarding-dependent.type"
import {DealType} from "../../deal.type"
import Imgix from "react-imgix"

type DealHeadProps = {
    deal: DealOnboardingDependentType
    showBookmark?: boolean
}

const DealHead: FunctionComponent<DealHeadProps> = ({ deal, showBookmark = true }) => {
    return (
        <div className={styles.head}>
            <div className={styles.logoAndTitle}>
                {deal instanceof DealType && (
                    <>
                        {deal.logo?.url && (
                            <div className={styles.logo}>
                                <div>
                                    <Imgix
                                        src={deal.logo.url}
                                        height={50}
                                        htmlAttributes={{ alt: deal.name }}
                                    />
                                </div>
                            </div>
                        )}
                    </>
                )}
                <div className={styles.title}>
                    {deal.name}
                </div>
            </div>
            {showBookmark && (
                <div className={styles.bookmark}>
                    <FavouriteBookmark
                        deal={deal}
                        bright={true}
                    />
                </div>
            )}
        </div>
    )
}

export default DealHead