import React, {FunctionComponent} from "react"
import Imgix from "react-imgix"
import {SyndicateType} from "../../../../syndicate/syndicate.type"
import styles from "../badge.module.sass"

type DealCardSyndicateBadgeProps = {
    syndicate: SyndicateType
}

const DealCardSyndicateBadge: FunctionComponent<DealCardSyndicateBadgeProps> = ({ syndicate }) => {
    const text = truncateString(syndicate.name, 24) + " Deal"
    return (
        <div className={styles.badge}>
            <div>
                <div className={styles.logo}>
                    <div>
                        <Imgix
                            src={syndicate.logo.url!}
                            height={11}
                            htmlAttributes={{ alt: syndicate.name }}
                        />
                    </div>
                </div>
                <div className={styles.text}>
                    <div>
                        {text}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DealCardSyndicateBadge

function truncateString(str: string, maxLength: number): string {
    if (str.length <= maxLength) return str
    return str.slice(0, maxLength) + "..."
}