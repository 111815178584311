import React, {FunctionComponent} from "react"
import DealDocument from "./document.component"
import styles from "./documents.module.sass"
import {DealDocumentType} from "./document.type"
import {DocumentType} from "../../document/document.type"

type DealDocumentsProps = {
    documents: (DealDocumentType | DocumentType)[]
    multipleColumns?: boolean
}

const DealDocuments: FunctionComponent<DealDocumentsProps> = ({
    documents,
    multipleColumns = true
}) => {
    if (documents.length < 1) return <></>
    return (
        <div className={`${styles.documents} ${multipleColumns ? styles.multipleColumns : ""}`}>
            {documents.map(document => (document._type === "DEAL_DOCUMENT") ? (
                <DealDocument
                    document={document}
                    key={`deal-document-${document.id!}`}
                />
            ) : (
                <DealDocument
                    document={document}
                    key={`deal-contract-${document.id!}`}
                />
            ))}
        </div>
    )
}

export default DealDocuments