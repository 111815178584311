/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddressOpenApi } from './AddressOpenApi';
import {
    AddressOpenApiFromJSON,
    AddressOpenApiFromJSONTyped,
    AddressOpenApiToJSON,
} from './AddressOpenApi';
import type { ContactOpenApi } from './ContactOpenApi';
import {
    ContactOpenApiFromJSON,
    ContactOpenApiFromJSONTyped,
    ContactOpenApiToJSON,
} from './ContactOpenApi';
import type { SalutationOpenApi } from './SalutationOpenApi';
import {
    SalutationOpenApiFromJSON,
    SalutationOpenApiFromJSONTyped,
    SalutationOpenApiToJSON,
} from './SalutationOpenApi';

/**
 * 
 * @export
 * @interface PersonOpenApi
 */
export interface PersonOpenApi {
    /**
     * 
     * @type {SalutationOpenApi}
     * @memberof PersonOpenApi
     */
    salutation?: SalutationOpenApi;
    /**
     * 
     * @type {string}
     * @memberof PersonOpenApi
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PersonOpenApi
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof PersonOpenApi
     */
    fullName?: string;
    /**
     * 
     * @type {AddressOpenApi}
     * @memberof PersonOpenApi
     */
    address?: AddressOpenApi;
    /**
     * 
     * @type {ContactOpenApi}
     * @memberof PersonOpenApi
     */
    contact?: ContactOpenApi;
    /**
     * 
     * @type {boolean}
     * @memberof PersonOpenApi
     */
    politicallyExposed: boolean;
}

/**
 * Check if a given object implements the PersonOpenApi interface.
 */
export function instanceOfPersonOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "politicallyExposed" in value;

    return isInstance;
}

export function PersonOpenApiFromJSON(json: any): PersonOpenApi {
    return PersonOpenApiFromJSONTyped(json, false);
}

export function PersonOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'salutation': !exists(json, 'salutation') ? undefined : SalutationOpenApiFromJSON(json['salutation']),
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'address': !exists(json, 'address') ? undefined : AddressOpenApiFromJSON(json['address']),
        'contact': !exists(json, 'contact') ? undefined : ContactOpenApiFromJSON(json['contact']),
        'politicallyExposed': json['politicallyExposed'],
    };
}

export function PersonOpenApiToJSON(value?: PersonOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'salutation': SalutationOpenApiToJSON(value.salutation),
        'firstName': value.firstName,
        'lastName': value.lastName,
        'fullName': value.fullName,
        'address': AddressOpenApiToJSON(value.address),
        'contact': ContactOpenApiToJSON(value.contact),
        'politicallyExposed': value.politicallyExposed,
    };
}

