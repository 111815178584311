/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IndividualInvestmentValuationOpenApi
 */
export interface IndividualInvestmentValuationOpenApi {
    /**
     * 
     * @type {string}
     * @memberof IndividualInvestmentValuationOpenApi
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof IndividualInvestmentValuationOpenApi
     */
    date: Date;
    /**
     * 
     * @type {number}
     * @memberof IndividualInvestmentValuationOpenApi
     */
    valuation: number;
}

/**
 * Check if a given object implements the IndividualInvestmentValuationOpenApi interface.
 */
export function instanceOfIndividualInvestmentValuationOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "valuation" in value;

    return isInstance;
}

export function IndividualInvestmentValuationOpenApiFromJSON(json: any): IndividualInvestmentValuationOpenApi {
    return IndividualInvestmentValuationOpenApiFromJSONTyped(json, false);
}

export function IndividualInvestmentValuationOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): IndividualInvestmentValuationOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'date': (new Date(json['date'])),
        'valuation': json['valuation'],
    };
}

export function IndividualInvestmentValuationOpenApiToJSON(value?: IndividualInvestmentValuationOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': (value.date.toISOString().substr(0,10)),
        'valuation': value.valuation,
    };
}

