import React, {FunctionComponent} from "react"
import {SubmitHandler, useForm} from "react-hook-form"
import {useSearchParams} from "react-router-dom"
import {SignUpType} from "./sign-up.type"
import ExclamationMark from "../../exclamation-mark/mark.component"
import ExclamationMarkInfo from "../../exclamation-mark/info.component"
import AccreditedInvestorStatusExplanation from "./ai-explanation.component"
import SubmitButton from "../../form/submit/submit.component"
import FormRow from "../../form/row/row.component"
import Checkbox from "../../form/fields/checkbox/checkbox.component"
import formStyles from "../../form/form.module.sass"
import EmailField from "../../form/fields/email/email.component"
import PasswordField from "../../form/fields/password/password.component"
import TextField from "../../form/fields/text/text.component"
import {getInvitationCodeQueryParameter} from "../../../domain/syndicate/invitation-code.util"
import {passwordPolicy} from "../../form/fields/password/password.policy"
import {PRIVACY_STATEMENT, TERMS_AND_CONDITIONS} from "../../../paths"

type SignUpFormProps = {
    onSubmit: SubmitHandler<SignUpType>
}

const SignUpForm: FunctionComponent<SignUpFormProps> = ({ onSubmit }) => {
    const [searchParams] = useSearchParams()
    const email = searchParams.get("email") || undefined
    const invitationCode = getInvitationCodeQueryParameter(searchParams)
    const shouldDisableEmailAndInvitationCodeField = email !== undefined && invitationCode !== undefined

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<SignUpType>({
        defaultValues: {
            email,
            invitationCode
        }
    })

    return (
        <form
            className={formStyles.form}
            onSubmit={handleSubmit(onSubmit)}
        >
            <FormRow content={{
                type: "one",
                element: (
                    <EmailField
                        placeholder="Your Email"
                        errors={errors}
                        prefill={email}
                        disabled={shouldDisableEmailAndInvitationCodeField}
                        reactHookFormRegister={{
                            name: "email",
                            register,
                            options: {
                                required: "Email is required."
                            }
                        }}
                    />
                )
            }}/>
            <FormRow content={{
                type: "one",
                element: (
                    <PasswordField
                        placeholder="Password"
                        errors={errors}
                        reactHookFormRegister={{
                            name: "password",
                            register,
                            options: passwordPolicy
                        }}
                        autoComplete="desired-password"
                    />
                )
            }}/>
            <FormRow content={{
                type: "one",
                element: (
                    <TextField
                        placeholder="Invitation Code (optional)"
                        errors={errors}
                        prefill={invitationCode}
                        disabled={shouldDisableEmailAndInvitationCodeField}
                        reactHookFormRegister={{
                            name: "invitationCode",
                            register
                        }}
                    />
                )
            }}/>
            <FormRow content={{
                type: "one",
                element: (
                    <Checkbox
                        label="I confirm that I’m an Accredited Investor"
                        id="aiStatusDeclaration"
                        errors={errors}
                        reactHookFormRegister={{
                            name: "aiStatusDeclaration",
                            register,
                            options: {
                                required: "You're only allowed to sign up if you are an Accredited Investor."
                            }
                        }}
                        exclamationMark={{
                            mark: <ExclamationMark modalId="aiDeclaration"/>,
                            info: (
                                <ExclamationMarkInfo
                                    modalId="aiDeclaration"
                                    title="Accredited Investor"
                                    content={<AccreditedInvestorStatusExplanation/>}
                                />
                            ),
                            labelFullWidth: true
                        }}
                    />
                )
            }}/>
            <FormRow content={{
                type: "one",
                element: (
                    <Checkbox
                        label={
                            <div>
                                I agree to the <a href={TERMS_AND_CONDITIONS} target="_blank" rel="noreferrer">Terms of
                                Use</a> and <a href={PRIVACY_STATEMENT} target="_blank" rel="noreferrer">Privacy
                                Policy</a>
                            </div>
                        }
                        id="termsConditionsAndPrivacyPolicy"
                        errors={errors}
                        reactHookFormRegister={{
                            name: "termsConditionsAndPrivacyPolicy",
                            register,
                            options: {
                                required: "Required."
                            }
                        }}
                        exclamationMark={{
                            mark: <ExclamationMark modalId="termsOfUseAndPrivacyPolicy"/>,
                            info: (
                                <ExclamationMarkInfo
                                    modalId="termsOfUseAndPrivacyPolicy"
                                    title="Terms of Use and Privacy Policy"
                                    content={<>You confirm that you have read, understood, and accepted the <a href={TERMS_AND_CONDITIONS} target="_blank" rel="noreferrer">Terms of Use</a> and <a href={PRIVACY_STATEMENT} target="_blank" rel="noreferrer">Privacy Policy</a>.</>}
                                />
                            ),
                            labelFullWidth: true
                        }}
                    />
                )
            }}/>
            <FormRow content={{
                type: "one",
                element: (
                    <Checkbox
                        label="I agree to the liability policy"
                        id="warranty"
                        errors={errors}
                        reactHookFormRegister={{
                            name: "warranty",
                            register,
                            options: {
                                required: "Required."
                            }
                        }}
                        exclamationMark={{
                            mark: <ExclamationMark modalId="liabilityPolicy"/>,
                            info: (
                                <ExclamationMarkInfo
                                    modalId="liabilityPolicy"
                                    title="NonPublic's Liability Policy"
                                    content={(
                                        <>
                                            NonPublic makes no representation or warranty whether express or implied and accepts no responsibility or liability for the accuracy and completeness of the information shared by third parties or by NonPublic on behalf of third parties on the platform. NonPublic is a technology service provider and does not provide any consultancy, advisory or due diligence services for startups or investors.
                                        </>
                                    )}
                                />
                            ),
                            labelFullWidth: true
                        }}
                    />
                )
            }}/>
            <SubmitButton label="Proceed"/>
        </form>
    )
}

export default SignUpForm