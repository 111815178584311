import {FunctionComponent, ReactNode, useEffect} from "react"
import {useLocation} from "react-router-dom"

type ScrollToTopProps = {
    children: ReactNode
}

const ScrollToTop: FunctionComponent<ScrollToTopProps> = ({ children }) => {
    let location = useLocation()

    useEffect(() => {
        window.scrollTo({ top: 0 })
    }, [location])

    return (
        <>{children}</>
    )
}

export default ScrollToTop