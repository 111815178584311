import React, {FunctionComponent} from "react"
import Tooltip from "../../../../tech/layout/tooltip/tooltip.component"
import DealDisclaimerTooltipContent from "./tooltip-content.component"
import styles from "./teaser.module.sass"

const DealDisclaimerTeaser: FunctionComponent = () => {
    return (
        <div className={styles.teaser}>
            <div>
                <div>
                    <span>Disclaimer: </span>
                    <span className={styles.text}>Please read before you proceed</span>
                </div>
            </div>
            <div className={styles.tooltip}>
                <Tooltip
                    infoIconSize={18}
                    maxWidth={400}
                    position={{
                        top: 16,
                        left: -44,
                        shiftLeft: -60
                    }}
                >
                    <DealDisclaimerTooltipContent/>
                </Tooltip>
            </div>
        </div>
    )
}

export default DealDisclaimerTeaser