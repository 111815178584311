import React, {FunctionComponent, useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import AuthLayoutWrapper from "../../../tech/auth/layout-wrapper.component"
import {DealWithSyndicatePublicType} from "../public/deal-public.type"
import {useApp} from "../../../tech/app/context/app.context"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import {SIGN_IN} from "../../../paths"
import DealSlideDeck from "./slide-deck/slide-deck.component"
import DealAbout from "./about/about.component"
import DealTiles from "./tiles/tiles.component"
import DealSummary from "./summary/summary.component"
import AuthWideLayoutWrapper from "../../../tech/auth/wide-wrapper.component"
import FixedContainer from "../../../tech/layout/fixed/fixed.component"
import SyndicateBanner from "../../syndicate/banner/banner.component"
import Button from "../../../tech/button/button.component"
import {ButtonSize} from "../../../tech/button/size.enum"
import {ButtonState} from "../../../tech/button/state.enum"
import {ButtonStyle} from "../../../tech/button/style.enum"
import styles from "./not-authenticated.module.sass"
import DealVideo from "./video/video.component"

const DealSingleNotAuthenticated: FunctionComponent = () => {
    let { id } = useParams()
    const app = useApp()
    const navigate = useNavigate()
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [dealWithSyndicate, setDealWithSyndicate] = useState<DealWithSyndicatePublicType>()

    useEffect(() => {
        const fetch = async () => {
            try {
                const result = id ? await app.fetchClient.dealApi.getPublic(id) : undefined
                if (result) {
                    setDealWithSyndicate(result)
                    setState("LOADED")
                }
                else {
                    navigate(SIGN_IN)
                }
            }
            catch (err) {
                setState("ERROR")
            }
        }
        fetch()
    }, [app.fetchClient, id, navigate])

    return (
        <AuthLayoutWrapper withBackgroundImage={false}>
            <AuthWideLayoutWrapper centerVertically={false}>
                {state === "LOADING" && <LoadingDots/>}
                {state === "LOADED" && dealWithSyndicate && (
                    <>
                        <SyndicateBanner
                            syndicate={dealWithSyndicate.syndicate}
                            placement="MOBILE"
                        />
                        <div className={styles.container}>
                            <div className={styles.right}>
                                <FixedContainer top={32}>
                                    <DealSummary deal={dealWithSyndicate.deal}/>
                                </FixedContainer>
                            </div>
                            <div className={styles.left}>
                                <SyndicateBanner
                                    syndicate={dealWithSyndicate.syndicate}
                                    placement="DESKTOP"
                                />
                                <DealSlideDeck slideDeck={dealWithSyndicate.deal.slideDeck}/>
                                <DealVideo deal={dealWithSyndicate.deal}/>
                                {dealWithSyndicate.deal.description && (
                                    <DealAbout description={dealWithSyndicate.deal.description}/>
                                )}
                                <DealTiles tiles={dealWithSyndicate.deal.tiles}/>
                            </div>
                        </div>
                    </>
                )}
                {state === "ERROR" && (
                    <div className={styles.notPubliclyAvailable}>
                        <Alert
                            type={AlertType.WARNING}
                            text="The deal is not publicly available. Sign in to the see the deal details."
                        />
                        <div className={styles.button}>
                            <Button
                                label="Go to sign in"
                                type="button"
                                size={ButtonSize.MEDIUM}
                                state={ButtonState.ACTIVE}
                                style={ButtonStyle.PRIMARY}
                                withArrow={true}
                                onClick={() => navigate(SIGN_IN)}
                            />
                        </div>
                    </div>
                )}
            </AuthWideLayoutWrapper>
        </AuthLayoutWrapper>
    )
}

export default DealSingleNotAuthenticated