import {FunctionComponent} from "react"
import {useNavigate} from "react-router-dom"
import ArrowRightIcon from "../../../tech/icon/arrow-right.component"
import {EXCLUSIVE_DEALS} from "../../../paths"
import {DealOnboardingDependentType} from "../onboarding-dependent.type"
import styles from "./exclusive-banner.module.sass"

type KycBannerProps = {
    exclusiveDeals: DealOnboardingDependentType[]
}

const ExclusiveBanner: FunctionComponent<KycBannerProps> = ({ exclusiveDeals }) => {
    const navigate = useNavigate()

    if (false && exclusiveDeals.length > 0) {
        return (
            <div
                className={styles.banner}
                onClick={() => navigate(EXCLUSIVE_DEALS)}
            >
                <div className={styles.text}>
                    <div className={styles.exclusive}>
                        Exclusive
                    </div>
                    <div className={styles.nonpublicDeals}>
                        NonPublic Deals
                    </div>
                </div>
                <div className={styles.arrow}>
                    <div>
                        <ArrowRightIcon
                            fillClass="fill-icon-white"
                            width={32}
                            height={32}
                        />
                    </div>
                </div>
            </div>
        )
    }
    return <></>
}

export default ExclusiveBanner