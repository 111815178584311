import {CompanyType} from "../company/company.type"
import {DealCategoryType} from "../deal-category/deal-category.type"
import {DealGeographyType} from "../deal-geography/deal-geography.type"
import {ImageType} from "../../tech/image/image.type"
import {
    CurrencyOpenApi,
    DealExitTypeOpenApi,
    DealIndustryOpenApi,
    DealOutcomeOpenApi,
    DealStageOpenApi,
    DealWaitingStateOpenApi
} from "../../generated"
import {DealGroupType} from "../deal-group/deal-group.type"
import {DealType} from "./deal.type"
import {InvestmentWithPaymentDetailsType} from "../investment/investment-with-payment-details.type"
import {DealInterface} from "./deal.interface"

export type DealOnboardingDependentType = DealIncompleteOnboardingType | DealType

export type DealOnboardingDependentWithInvestmentType = {
    deal: DealOnboardingDependentType
    investmentWithPaymentDetails?: InvestmentWithPaymentDetailsType
}

export class DealIncompleteOnboardingType implements DealInterface {
    public id: string | undefined
    public nameIncompleteOnboarding: string
    public descriptionIncompleteOnboarding: string
    public company: CompanyType | undefined
    public categories: DealCategoryType[]
    public _geography: DealGeographyType | undefined
    public image: ImageType | undefined
    public _stage: DealStageOpenApi
    public _outcome: DealOutcomeOpenApi
    public waitingState: DealWaitingStateOpenApi | undefined
    public _industry: DealIndustryOpenApi
    public _finalDeadline: Date
    public exclusive: boolean
    public dealGroups: DealGroupType[]
    public investorGroupIds: string[]
    public exited: Date | undefined
    public exitType: DealExitTypeOpenApi | undefined
    public published: boolean
    public imported: boolean
    public favourite: boolean
    public objectType: "Deal" | "DealIncompleteOnboarding"

    constructor({
        id,
        nameIncompleteOnboarding,
        descriptionIncompleteOnboarding,
        company,
        categories,
        geography,
        image,
        stage,
        outcome,
        waitingState,
        industry,
        finalDeadline,
        exclusive,
        dealGroups,
        investorGroupIds,
        exited,
        exitType,
        published,
        imported,
        favourite,
        objectType
    } : {
        id?: string,
        nameIncompleteOnboarding: string
        descriptionIncompleteOnboarding: string
        company: CompanyType | undefined
        categories: DealCategoryType[]
        geography: DealGeographyType | undefined
        image: ImageType | undefined
        stage: DealStageOpenApi
        outcome: DealOutcomeOpenApi
        waitingState: DealWaitingStateOpenApi | undefined
        industry: DealIndustryOpenApi
        finalDeadline: Date
        exclusive: boolean
        dealGroups: DealGroupType[]
        investorGroupIds: string[]
        exited: Date | undefined
        exitType: DealExitTypeOpenApi | undefined
        published: boolean
        imported: boolean
        favourite: boolean
        objectType: "Deal" | "DealIncompleteOnboarding"
    }) {
        this.id = id
        this.nameIncompleteOnboarding = nameIncompleteOnboarding
        this.descriptionIncompleteOnboarding = descriptionIncompleteOnboarding
        this.company = company
        this.categories = categories
        this._geography = geography
        this.image = image
        this._stage = stage
        this._outcome = outcome
        this.waitingState = waitingState
        this._industry = industry
        this._finalDeadline = finalDeadline
        this.exclusive = exclusive
        this.dealGroups = dealGroups
        this.investorGroupIds = investorGroupIds
        this.exited = exited
        this.exitType = exitType
        this.published = published
        this.imported = imported
        this.favourite = favourite
        this.objectType = objectType
    }

    get name(): string {
        return this.nameIncompleteOnboarding
    }

    get description(): string {
        return this.descriptionIncompleteOnboarding
    }

    get currency(): CurrencyOpenApi | undefined {
        return undefined
    }

    get geography(): DealGeographyType | undefined {
        return this._geography
    }

    get industry(): DealIndustryOpenApi | undefined {
        return this._industry
    }

    get finalDeadline(): Date | undefined {
        return this._finalDeadline
    }

    get investedAmount(): number | undefined {
        return undefined
    }

    get investors(): string[] | undefined {
        return undefined
    }

    get logo(): ImageType | undefined {
        return undefined
    }

    get minTarget(): number | undefined {
        return undefined
    }

    get maxTarget(): number | undefined {
        return undefined
    }

    get outcome(): DealOutcomeOpenApi | undefined {
        return this._outcome
    }

    get stage(): DealStageOpenApi | undefined {
        return this._stage
    }

    get termsTags(): string[] | undefined {
        return undefined
    }

    get videoYoutubeCode(): string | undefined {
        return undefined
    }

    isPublic(): boolean {
        return false
    }
}