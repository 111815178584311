import {DocumentOpenApi} from "../../generated"
import {DocumentType} from "./document.type"
import {mapOpenApiToFile} from "../file/file.mapper"

export function mapOpenApiToDocument(document: DocumentOpenApi): DocumentType {
    return {
        id: document.id,
        name: document.name,
        signed: document.signed,
        file: document.file && mapOpenApiToFile(document.file),
        _type: "DOCUMENT"
    }
}