import React, {FunctionComponent} from "react"
import {Control, FieldErrors, useFieldArray, UseFormRegister} from "react-hook-form"
import {IndividualInvestmentType} from "../../individual-investment.type"
import IndividualInvestmentFormValuation from "./valuation.component"
import Button from "../../../../../tech/button/button.component"
import {ButtonSize} from "../../../../../tech/button/size.enum"
import {ButtonState} from "../../../../../tech/button/state.enum"
import {ButtonStyle} from "../../../../../tech/button/style.enum"

type IndividualInvestmentFormValuationsProps = {
    control: Control<IndividualInvestmentType, any>
    errors: FieldErrors<IndividualInvestmentType>
    register: UseFormRegister<IndividualInvestmentType>
}

const IndividualInvestmentFormValuations: FunctionComponent<IndividualInvestmentFormValuationsProps> = ({
    control,
    errors,
    register
}) => {
    const {fields, append, remove} = useFieldArray({name: "valuations", control})

    // @ts-ignore
    const add = () => append({})

    const del = (i: number) => remove(i)

    return (
        <div>
            {fields
                .sort((a, b) => sort(a.date, b.date))
                .map((valuation, index) => (
                <IndividualInvestmentFormValuation
                    control={control}
                    errors={errors}
                    register={register}
                    del={del}
                    numberOfValuations={fields.length}
                    index={index}
                    key={valuation.id}
                />
            ))}
            <Button
                label="Add a valuation"
                type="button"
                size={ButtonSize.LARGE}
                state={ButtonState.ACTIVE}
                style={ButtonStyle.SECONDARY}
                onClick={add}
                fullWidth={true}
            />
        </div>
    )
}

export default IndividualInvestmentFormValuations

function sort(a: Date | undefined, b: Date | undefined): number {
    if (a === undefined) return 1
    if (b === undefined) return -1
    if (a < b) return -1
    if (a > b) return 1
    return 0
}