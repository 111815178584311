import {
    InvestorKycOpenApi,
    InvestorOpenApi,
    InvestorSyndicationOpenApi,
    InvestorWithDetailsOpenApi
} from "../../generated"
import {InvestorKycType, InvestorSyndicationType, InvestorType, InvestorWithDetailsType} from "./investor.type"
import {mapOpenApiToPerson} from "../person/person.mapper"
import {mapOpenApiToSubscription} from "../subscription/subscription.mapper"
import {mapOpenApiToSyndicate} from "../syndicate/syndicate.mapper"

export function mapOpenApiToInvestorWithDetails(
    investorWithDetails: InvestorWithDetailsOpenApi
): InvestorWithDetailsType {
    return {
        investor: mapOpenApiToInvestor(investorWithDetails.investor),
        subscription: investorWithDetails.subscription
            && mapOpenApiToSubscription(investorWithDetails.subscription),
        syndication: mapOpenApiToInvestorSyndication(investorWithDetails.syndication)
    }
}

export function mapOpenApiToInvestor(investor: InvestorOpenApi): InvestorType {
    return {
        id: investor.id,
        person: investor.person && mapOpenApiToPerson(investor.person),
        kyc: investor.kyc && mapOpenApiToInvestorKyc(investor.kyc),
        companyName: investor.companyName,
    }
}

export function mapOpenApiToInvestorKyc(kyc: InvestorKycOpenApi): InvestorKycType {
    return {
        complete: kyc.complete === undefined ? false : kyc.complete,
        needsUpdate: kyc.needsUpdate === undefined ? false : kyc.needsUpdate,
        passportValidTill: kyc.passportValidTill,
        proofOfAddressValidTill: kyc.proofOfAddressValidTill,
        proofOfAiStatusAvailable: kyc.proofOfAiStatusAvailable
    }
}

export function mapOpenApiToInvestorSyndication(syndication: InvestorSyndicationOpenApi): InvestorSyndicationType {
    return {
        memberships: syndication.memberships.map(mapOpenApiToSyndicate),
        syndicateOnlyInvestor: syndication.syndicateOnlyInvestor
    }
}