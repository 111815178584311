import {
    CurrencyOpenApi,
    DealExitTypeOpenApi,
    DealIndustryOpenApi,
    DealOutcomeOpenApi,
    DealStageOpenApi,
    DealWaitingStateOpenApi
} from "../../generated"
import {DealGroupType} from "../deal-group/deal-group.type"
import {DealCategoryType} from "../deal-category/deal-category.type"
import {DealGeographyType} from "../deal-geography/deal-geography.type"
import {DealTileType} from "./tile/tile.type"
import {SinglePurposeVehicleType} from "../single-purpose-vehicle/single-purpose-vehicle.type"
import {ImageType} from "../../tech/image/image.type"
import {CompanyType} from "../company/company.type"
import {DealIncompleteOnboardingType} from "./onboarding-dependent.type"
import {DealInterface} from "./deal.interface"
import {DealDocumentType} from "./document/document.type"

export class DealType extends DealIncompleteOnboardingType implements DealInterface {
    public nameCompleteOnboarding: string
    public descriptionCompleteOnboarding: string
    public _minTarget: number
    public _maxTarget: number
    public minTicket: number
    public _currency: CurrencyOpenApi
    public _investedAmount: number
    public _logo: ImageType | undefined
    public tiles: DealTileType[]
    public slideDeck: DealSlideDeckType | undefined
    public _videoYoutubeCode: string | undefined
    public _investors: string[]
    public documents: DealDocumentType[]
    public event: DealEventType | undefined
    public singlePurposeVehicle: SinglePurposeVehicleType
    public _termsTags: string[]

    constructor({
        id,
        nameCompleteOnboarding,
        nameIncompleteOnboarding,
        descriptionCompleteOnboarding,
        descriptionIncompleteOnboarding,
        company,
        categories,
        geography,
        logo,
        image,
        stage,
        outcome,
        waitingState,
        industry,
        minTarget,
        maxTarget,
        minTicket,
        finalDeadline,
        currency,
        exclusive,
        investedAmount,
        dealGroups,
        investorGroupIds,
        investors,
        termsTags,
        tiles,
        slideDeck,
        videoYoutubeCode,
        documents,
        event,
        singlePurposeVehicle,
        exited,
        exitType,
        published,
        imported,
        favourite,
        objectType
    } : {
        id?: string
        nameCompleteOnboarding: string
        nameIncompleteOnboarding: string
        descriptionCompleteOnboarding: string
        descriptionIncompleteOnboarding: string
        company: CompanyType | undefined
        categories: DealCategoryType[]
        geography: DealGeographyType | undefined
        logo: ImageType | undefined
        image: ImageType | undefined
        stage: DealStageOpenApi
        outcome: DealOutcomeOpenApi
        waitingState: DealWaitingStateOpenApi | undefined
        industry: DealIndustryOpenApi
        minTarget: number
        maxTarget: number
        minTicket: number
        finalDeadline: Date
        currency: CurrencyOpenApi
        exclusive: boolean
        investedAmount: number
        dealGroups: DealGroupType[]
        investorGroupIds: string[]
        investors: string[]
        termsTags: string[]
        tiles: DealTileType[]
        slideDeck: DealSlideDeckType | undefined
        videoYoutubeCode: string | undefined
        documents: DealDocumentType[]
        event: DealEventType | undefined
        singlePurposeVehicle: SinglePurposeVehicleType
        exited: Date | undefined
        exitType: DealExitTypeOpenApi | undefined
        published: boolean
        imported: boolean
        favourite: boolean
        objectType: "Deal" | "DealIncompleteOnboarding"
    }) {
        super({
            id: id,
            nameIncompleteOnboarding: nameIncompleteOnboarding,
            descriptionIncompleteOnboarding: descriptionIncompleteOnboarding,
            company: company,
            categories: categories,
            geography: geography,
            image: image,
            stage: stage,
            outcome: outcome,
            waitingState: waitingState,
            industry: industry,
            finalDeadline: finalDeadline,
            exclusive: exclusive,
            dealGroups: dealGroups,
            investorGroupIds: investorGroupIds,
            exited: exited,
            exitType: exitType,
            published: published,
            imported: imported,
            favourite: favourite,
            objectType: objectType
        })
        this.nameCompleteOnboarding = nameCompleteOnboarding
        this.descriptionCompleteOnboarding = descriptionCompleteOnboarding
        this._minTarget = minTarget
        this._maxTarget = maxTarget
        this.minTicket = minTicket
        this._currency = currency
        this._investedAmount = investedAmount
        this._logo = logo
        this.tiles = tiles
        this.slideDeck = slideDeck
        this._videoYoutubeCode = videoYoutubeCode
        this._investors = investors
        this.documents = documents
        this.event = event
        this.singlePurposeVehicle = singlePurposeVehicle
        this._termsTags = termsTags
    }

    get name(): string {
        return this.nameCompleteOnboarding
    }

    get description(): string {
        return this.descriptionCompleteOnboarding
    }

    get currency(): CurrencyOpenApi | undefined {
        return this._currency
    }

    get investedAmount(): number | undefined {
        return this._investedAmount
    }

    get investors(): string[] | undefined {
        return this._investors
    }

    get minTarget(): number | undefined {
        return this._minTarget
    }

    get maxTarget(): number | undefined {
        return this._maxTarget
    }

    get logo(): ImageType | undefined {
        return this._logo
    }

    get outcome(): DealOutcomeOpenApi | undefined {
        return this._outcome
    }

    get termsTags(): string[] | undefined {
        return this._termsTags
    }

    get videoYoutubeCode(): string | undefined {
        return this._videoYoutubeCode
    }
}

export type DealEventType = {
    title: string
    start: Date
    end?: Date
    location?: string
    url?: string
}

export type DealSlideDeckType = {
    fileId: string
    url: string | undefined
}

export const mapDealIndustryToString = (industry: DealIndustryOpenApi): string => {
    switch (industry) {
        case "ADVERTISING": return "Advertising"
        case "AGRICULTURAL_TECH": return "Agritech"
        case "AI": return "AI"
        case "CLIMATE_TECH": return "Climate Tech"
        case "COMMERCE": return "Commerce"
        case "CONSUMER": return "Consumer"
        case "DEEP_TECH": return "Deep Tech"
        case "EDUCATION": return "Education"
        case "ENTERTAINMENT": return "Entertainment"
        case "FINTECH": return "Fintech"
        case "FOOD_AND_RESTAURANT_TECH": return "Food & Restaurant Tech"
        case "FUND": return "Fund"
        case "HEALTH": return "Health"
        case "HOSPITALITY_AND_PROP_TECH": return "Hospitality & Prop Tech"
        case "MOBILITY_AND_LOGISTICS": return "Mobility & Logistics"
        case "OTHERS": return "Others"
        case "SAAS": return "SaaS"
    }
}

export const mapDealStageToString = (stage: DealStageOpenApi): string => {
    switch (stage) {
        case "NONE": return "None"
        case "EARLY_STAGE": return "Early Stage"
        case "GROWTH_STAGE": return "Growth Stage"
        case "LATE_STAGE": return "Late Stage"
        case "FUND": return "Fund"
        case "SEED": return "Seed"
        case "SERIES_A": return "Series A"
        case "SERIES_B": return "Series B"
        case "SERIES_C": return "Series C"
        case "PRE_IPO": return "Pre-IPO"
    }
}

export const ACTIVE_STRING = "Active"
export const EXIT_STRING = "Exit"

export function mapDealExitTypeToString(exitType: DealExitTypeOpenApi | undefined): string | undefined {
    if (exitType === undefined) return undefined
    switch (exitType) {
        case "DEAD": return "Ceased"
        case "EXIT": return EXIT_STRING
    }
}

export function assembleExitString(deal: DealType): string {
    return deal.exited === undefined || deal.exitType === undefined
        ? ACTIVE_STRING
        : mapDealExitTypeToString(deal.exitType)!
}