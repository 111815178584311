import React, {FunctionComponent} from "react"
import {formatAmountWithCurrency} from "../../../../../../tech/format/amount.util"
import {InvestmentType} from "../../../../investment.type"
import {PaymentDetailsAccountType} from "../../../../payment-details/payment-details.type"
import PaymentDetailField from "./detail.component"
import styles from "./payment-details.module.sass"

type PendingInvestmentCardPaymentDetailsAccountProps = {
    investment: InvestmentType
    paymentDetailsAccount: PaymentDetailsAccountType
}

const PendingInvestmentCardPaymentDetailsAccount: FunctionComponent<PendingInvestmentCardPaymentDetailsAccountProps> = ({
    investment,
    paymentDetailsAccount
}) => {
    return (
        <div>
            <div className={styles.fields}>
                <PaymentDetailField
                    label="Account Holder"
                    text={paymentDetailsAccount.accountHolder}
                    value={paymentDetailsAccount.accountHolder}
                />
                <PaymentDetailField
                    label="Account Number"
                    text={paymentDetailsAccount.number}
                    value={paymentDetailsAccount.number}
                />
                {paymentDetailsAccount.manualReconciliation?.transferOrigin !== "WITHIN_US" && (
                    <PaymentDetailField
                        label="SWIFT/BIC"
                        text={paymentDetailsAccount.manualReconciliation!.swift!}
                        value={paymentDetailsAccount.manualReconciliation!.swift!}
                    />
                )}
                {paymentDetailsAccount.manualReconciliation?.transferOrigin === "WITHIN_US" && (
                    <>
                        <PaymentDetailField
                            label="ACH Routing Number"
                            text={paymentDetailsAccount.manualReconciliation.achRoutingNumber!}
                            value={paymentDetailsAccount.manualReconciliation.achRoutingNumber!}
                        />
                        <PaymentDetailField
                            label="ABA/Fedwire"
                            text={paymentDetailsAccount.manualReconciliation.aba!}
                            value={paymentDetailsAccount.manualReconciliation.aba!}
                        />
                    </>
                )}
                {paymentDetailsAccount.manualReconciliation?.transferOrigin !== "WITHIN_US" && <BankName paymentDetailsAccount={paymentDetailsAccount} />}
            </div>
            {paymentDetailsAccount.manualReconciliation?.transferOrigin === "WITHIN_US" && <BankName paymentDetailsAccount={paymentDetailsAccount} />}
            <PaymentDetailField
                label="Bank Address"
                text={paymentDetailsAccount.bankAddress}
                value={paymentDetailsAccount.bankAddress}
            />
            <PaymentDetailField
                label="Amount To Transfer"
                text={formatAmountWithCurrency(investment.deal.currency !== investment.currency
                    ? investment.amountWithPlacementFeeAndExchangeFeeAtExchangeRate!
                    : investment.amountWithPlacementFeeAndExchangeFee!, investment.currency!)}
                value={investment.deal.currency !== investment.currency
                    ? investment.amountWithPlacementFeeAndExchangeFeeAtExchangeRate!
                    : investment.amountWithPlacementFeeAndExchangeFee!}
            />
            <Reference investment={investment}/>
        </div>
    )
}

export default PendingInvestmentCardPaymentDetailsAccount

const BankName: FunctionComponent<{ paymentDetailsAccount: PaymentDetailsAccountType }> = ({ paymentDetailsAccount }) => {
    return (
        <PaymentDetailField
            label="Bank Name"
            text={paymentDetailsAccount.bankName}
            value={paymentDetailsAccount.bankName}
        />
    )
}

const Reference: FunctionComponent<{ investment: InvestmentType }> = ({ investment }) => {
    const reference = `${investment.investor.person?.firstName} ${investment.investor.person?.lastName}, ${investment.deal.name}`
    return (
        <PaymentDetailField
            label="Reference"
            text={reference}
            value={reference}
        />
    )
}