import React, {FunctionComponent, useState} from "react"
import {SubmitHandler, useForm} from "react-hook-form"
import {updatePassword} from "aws-amplify/auth"
import {isFederatedUser} from "../../../../tech/auth/auth.util"
import ProfileTile from "../tile/tile.component"
import LoadingDots from "../../../../tech/loading/dots/dots.component"
import {passwordPolicy} from "../../../../tech/form/fields/password/password.policy"
import SubmitButton from "../../../../tech/form/submit/submit.component"
import Alert from "../../../../tech/alert/alert.component"
import {AlertType} from "../../../../tech/alert/type.enum"
import formStyles from "../../../../tech/form/form.module.sass"
import PasswordField from "../../../../tech/form/fields/password/password.component"
import FormRow from "../../../../tech/form/row/row.component"
import {ButtonSize} from "../../../../tech/button/size.enum"
import styles from "./change-password.module.sass"
import {useApp} from "../../../../tech/app/context/app.context"

const ProfileChangePassword: FunctionComponent = () => {
    const app = useApp()
    const [state, setState] = useState<"LOADING" | "NONE" | "ERROR" | "SUCCESS">("NONE")

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<ChangePasswordType>()

    const onSubmit: SubmitHandler<ChangePasswordType> = async (changePassword) => {
        try {
            setState("LOADING")
            await updatePassword({
                oldPassword: changePassword.oldPassword,
                newPassword: changePassword.newPassword
            })
            setState("SUCCESS")
        }
        catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    if (isFederatedUser(app.currentUser)) {
        return <></>
    }

    return (
        <ProfileTile
            title="Password"
            collapsible={true}
            children={(
                <div className={styles.changePassword}>
                    <div className={styles.description}>
                        The password must contain at least one letter, one digit and must have at least 8 characters.
                    </div>
                    {state === "LOADING" &&  <LoadingDots/>}
                    {state === "NONE" && (
                        <div className={styles.form}>
                            <form
                                className={formStyles.form}
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <FormRow content={{
                                    type: "one",
                                    element: (
                                        <PasswordField
                                            placeholder="Old password"
                                            errors={errors}
                                            reactHookFormRegister={{
                                                name: "oldPassword",
                                                register,
                                                options: {
                                                    required: "Password is required."
                                                }
                                            }}
                                            autoComplete="current-password"
                                        />
                                    )
                                }}/>
                                <FormRow content={{
                                    type: "one",
                                    element: (
                                        <PasswordField
                                            placeholder="New password"
                                            errors={errors}
                                            reactHookFormRegister={{
                                                name: "newPassword",
                                                register,
                                                options: passwordPolicy
                                            }}
                                            autoComplete="new-password"
                                        />
                                    )
                                }}/>
                                <SubmitButton
                                    label="Change Password"
                                    position="RIGHT"
                                    size={ButtonSize.SMALL}
                                />
                            </form>
                        </div>
                    )}
                    {state === "SUCCESS" && (
                        <div className={styles.alert}>
                            <Alert
                                type={AlertType.SUCCESS}
                                text="Your password has been changed"
                            />
                        </div>
                    )}
                    {state === "ERROR" && (
                        <div className={styles.alert}>
                            <Alert
                                type={AlertType.ERROR}
                                text="Failed to change password."
                            />
                        </div>
                    )}
                </div>
            )}
        />
    )
}

export default ProfileChangePassword

type ChangePasswordType = {
    oldPassword: string
    newPassword: string
}