import {GeneralDataFormType} from "./form.type"
import {GeneralDataSaveRequestOpenApi} from "../../../../../generated"

export function mapGeneralDataFormToRequest(data: GeneralDataFormType): GeneralDataSaveRequestOpenApi {
    return {
        companyName: data.companyName,
        person: {
            salutation: data.salutation,
            firstName: data.firstName,
            lastName: data.lastName,
            address: data.address,
            contact: data.contact,
            politicallyExposed: !data.notPoliticallyExposed
        }
    }
}