import React, {FunctionComponent} from "react"
import styles from "./tooltip-content.module.sass"

const DealDisclaimerTooltipContent: FunctionComponent = () => {
    return (
        <div className={styles.tooltip}>
            <strong>Disclaimer</strong>
            <small>
                NonPublic provides startups with a technology platform (the "Platform") where
                they can launch and manage their fundraising SPVs (Special Purpose Vehicles). You are not relying on
                NonPublic to, and you understand that we do not, review, confirm, monitor, endorse, support, represent,
                guarantee or have any knowledge of the completeness, truthfulness, accuracy or reliability of any
                content or
                communications posted via the Platform or sent by the startups or endorse any opinions expressed via the
                startups. NonPublic is not licensed, regulated by, or in any way associated with MAS.
            </small>
        </div>
    )
}

export default DealDisclaimerTooltipContent