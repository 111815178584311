import React, {FunctionComponent} from "react"
import styles from "./tooltip.module.sass"
import Tooltip from "../../../../../../tech/layout/tooltip/tooltip.component"

const InvestorDashboardCurrentValueTooltip: FunctionComponent = () => {
    return (
        <Tooltip padding={{ x: 8, y: 6 }}>
            <div className={styles.tooltip}>
                <div>
                    <strong>Realized gains</strong> typically refers to the actual benefits or outcomes obtained from a product, service, or investment, as opposed to potential or theoretical value.<br/>
                    It represents the tangible results achieved in practice, often after an action has been taken or a project has been completed.
                </div>
                <div>
                    <strong>Unrealized value</strong> refers to the potential benefits or gains that have not yet been fully obtained or recognized. It represents the value that exists but has not been actualized or realized due to various factors such as timing, market conditions, or incomplete actions.
                </div>
            </div>
        </Tooltip>
    )
}

export default InvestorDashboardCurrentValueTooltip