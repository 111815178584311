import React, {FunctionComponent, ReactNode} from "react"
import {FileUploadStateType} from "../state.type"
import FileUploadDesktopUpload from "./upload/upload.component"
import FileUploadDesktopUploading from "./uploading/uploading.component"
import styles from "./desktop.module.sass"
import CrossCircleIcon from "../../../../tech/icon/cross-circle.component"

type FileUploadDesktopProps = {
    label: string
    description: string | ReactNode
    close: () => void
    uploadState: FileUploadStateType
    uploadFile: (file: File) => void
}

const FileUploadDesktop: FunctionComponent<FileUploadDesktopProps> = ({
    label,
    description,
    close,
    uploadState,
    uploadFile
}) => {
    return (
        <div className={styles.desktop}>
            <div className={styles.container}>
                <div>
                    <div>
                        <div className={styles.head}>
                            <div>
                                <div className={styles.title}>
                                    {label}
                                </div>
                                <div className={styles.description}>
                                    {description}
                                </div>
                            </div>
                            <div>
                                <div
                                    className={styles.close}
                                    onClick={close}
                                >
                                    <CrossCircleIcon
                                        width={32}
                                        height={32}
                                        fillClass="fill-icon-theme"
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            {{
                                "NONE": (
                                    <FileUploadDesktopUpload
                                        label={label}
                                        uploadFile={uploadFile}
                                    />
                                ),
                                "ERROR": (
                                    <FileUploadDesktopUpload
                                        label={label}
                                        uploadFile={uploadFile}
                                    />
                                ),
                                "ERROR_TOO_LARGE": (
                                    <FileUploadDesktopUpload
                                        label={label}
                                        uploadFile={uploadFile}
                                    />
                                ),
                                "UPLOADING": <FileUploadDesktopUploading uploadFile={uploadFile}/>,
                                "SUCCESS": <></>
                            }[uploadState]}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FileUploadDesktop