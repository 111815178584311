import React, {FunctionComponent, ReactNode} from "react"
import styles from "./wide-wrapper.module.sass"

type AuthWideLayoutWrapperProps = {
    children: ReactNode
    centerVertically?: boolean
}

const AuthWideLayoutWrapper: FunctionComponent<AuthWideLayoutWrapperProps> = ({
    children,
    centerVertically = true
}) => {
    return (
        <div className={`${styles.wrapper} ${centerVertically ? styles.centerVertically : ""}`}>
            <div className={styles.content}>
                {children}
            </div>
        </div>
    )
}

export default AuthWideLayoutWrapper