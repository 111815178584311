import React, {FunctionComponent} from "react"
import styles from "./disclaimer.module.sass"
import Tooltip from "../../../../tech/layout/tooltip/tooltip.component"
import DealDisclaimerTooltipContent from "./tooltip-content.component"

const DealDisclaimer: FunctionComponent = () => {
    return (
        <div className={styles.disclaimer}>
            <div className={styles.title}>
                <div className={styles.titleText}>
                    Disclaimer
                </div>
                <div className={styles.info}>
                    <Tooltip
                        infoIconSize={20}
                        maxWidth={280}
                        position={{
                            top: 16,
                            left: -44,
                            shiftLeft: -60
                        }}
                    >
                        <DealDisclaimerTooltipContent/>
                    </Tooltip>
                </div>
            </div>
            <div className={styles.readFirst}>
                Please read before you proceed.
            </div>
            <div className={styles.text}>
                NonPublic provides startups with a technology platform (the "Platform") where
                they can launch and manage their fundraising SPVs (Special Purpose Vehicles). You are not relying on
                NonPublic to, and you understand that we do not, review, confirm, monitor, endorse, support, represent,
                guarantee or have any knowledge of the completeness, truthfulness, accuracy or reliability of any content or
                communications posted via the Platform or sent by the startups or endorse any opinions expressed via the
                startups. NonPublic is not licensed, regulated by, or in any way associated with MAS.
            </div>
        </div>
    )
}

export default DealDisclaimer