import React, {FunctionComponent} from "react"
import MenuItem from "./item/item.component"
import {DEAL, DEALS, FAVOURITE_DEALS, HELP, INVESTOR_DASHBOARD, PENDING_INVESTMENTS, PROFILE} from "../../../paths"
import BankIcon from "../../icon/bank.component"
import BookmarkIcon from "../../icon/bookmark.component"
import ChartLineIcon from "../../icon/chart-line.component"
import HourglassIcon from "../../icon/hourglass.component"
import MenuPersonIcon from "./person-icon/person-icon.component"
import QuestionCircleIcon from "../../icon/question-circle.component"
import {RouteData} from "../../routing/route.type"
import {DealOnboardingDependentWithInvestmentType} from "../../../domain/deal/onboarding-dependent.type"
import styles from "./menu.module.sass"
import {shouldShowInMenu} from "./menu.util"

type MenuProps = {
    closeMenu: () => void
    openDeals: DealOnboardingDependentWithInvestmentType[]
    relevantRoutes: RouteData[]
}

const Menu: FunctionComponent<MenuProps> = ({
    closeMenu,
    openDeals,
    relevantRoutes
}) => {
    const firstMenuItemText = shouldShowInMenu("DEALS", relevantRoutes)
        ? "Deals"
        : openDeals && openDeals.length > 0
        ? openDeals[0].deal.name
        : "Deals"
    const firstMenuItemRoute = shouldShowInMenu("DEALS", relevantRoutes)
        ? DEALS
        : openDeals && openDeals.length > 0
        ? DEAL(openDeals[0].deal.id!)
        : DEALS
    return (
        <div className={styles.menu}>
            <MenuItem
                icon={(
                    <BankIcon
                        width={24}
                        height={24}
                        fillClass="fill-icon-inactive"
                    />
                )}
                text={firstMenuItemText}
                route={firstMenuItemRoute}
                closeMenu={closeMenu}
            />
            {false && shouldShowInMenu("FAVOURITE_DEALS", relevantRoutes) && (
                <MenuItem
                    icon={(
                        <BookmarkIcon
                            width={24}
                            height={24}
                            fillClass="fill-icon-inactive"
                        />
                    )}
                    text="Bookmarks"
                    route={FAVOURITE_DEALS}
                    closeMenu={closeMenu}
                />
            )}
            {false && shouldShowInMenu("PENDING_INVESTMENTS", relevantRoutes) && (
                <MenuItem
                    icon={(
                        <HourglassIcon
                            width={24}
                            height={24}
                            fillClass="fill-icon-inactive"
                        />
                    )}
                    text="Pending Investments"
                    route={PENDING_INVESTMENTS}
                    closeMenu={closeMenu}
                />
            )}
            {shouldShowInMenu("INVESTOR_DASHBOARD", relevantRoutes) && (
                <MenuItem
                    icon={(
                        <ChartLineIcon
                            width={24}
                            height={24}
                            fillClass="fill-icon-inactive"
                        />
                    )}
                    text="Dashboard"
                    route={INVESTOR_DASHBOARD}
                    closeMenu={closeMenu}
                />
            )}
            <MenuItem
                icon={(
                    <QuestionCircleIcon
                        width={24}
                        height={24}
                        fillClass="fill-icon-inactive"
                    />
                )}
                text="Help"
                route={HELP}
                closeMenu={closeMenu}
                hideOnDesktop
            />
            <MenuItem
                icon={<MenuPersonIcon/>}
                text="Profile"
                route={PROFILE}
                closeMenu={closeMenu}
                hideOnDesktop
            />
        </div>
    )
}

export default Menu