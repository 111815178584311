import React, {FunctionComponent, ReactNode, useState} from "react"
import {KycDocumentUploadStateOpenApi} from "../../../../../../generated"
import {FileType} from "../../../../../file/file.type"
import FileUpload from "../../../../../file/upload/upload.component"
import styles from "./document.module.sass"
import Alert from "../../../../../../tech/alert/alert.component"
import {AlertType} from "../../../../../../tech/alert/type.enum"
import {formatDate} from "../../../../../../tech/format/datetime.util"

type InvestorOnboardingKycUploadDocumentProps = {
    label: string
    description: string | ReactNode
    fileIdentifier: string
    updateFile: (file?: FileType) => void
    state: KycDocumentUploadStateOpenApi
    rejectionComment?: string
    validTill?: Date
}

const InvestorOnboardingKycUploadDocument: FunctionComponent<InvestorOnboardingKycUploadDocumentProps> = ({
    label,
    description,
    fileIdentifier,
    updateFile,
    state,
    rejectionComment,
    validTill
}) => {
    const [uploaded, setUploaded] = useState<boolean>(false)

    const updateFileInternal = (file?: FileType) => {
        setUploaded(true)
        updateFile(file)
    }

    return (
        <div className={styles.kycDocument}>
            <FileUpload
                label={label}
                description={description}
                fileIdentifier={fileIdentifier}
                updateFile={updateFileInternal}
                alert={uploaded ? <></> : assembleAlert(state, rejectionComment, validTill)}
            />
        </div>
    )
}

export default InvestorOnboardingKycUploadDocument

function assembleAlert(
    state: KycDocumentUploadStateOpenApi,
    rejectionComment?: string,
    validTill?: Date
): ReactNode {
    if (state === "REJECTED") {
        return (
            <div className={styles.alert}>
                <Alert
                    type={AlertType.WARNING}
                    text={assembleRejectedAlertText(rejectionComment)}
                />
            </div>
        )
    }

    if (state === "UPDATE_REQUIRED") {
        return (
            <div className={styles.alert}>
                <Alert
                    type={AlertType.INFO}
                    text={assembleUpdateRequiredAlertText(validTill)}
                />
            </div>
        )
    }

    if (state === "NEW_UPLOAD_REQUIRED" && validTill) {
        return (
            <div className={styles.alert}>
                <Alert
                    type={AlertType.INFO}
                    text={`This document expired on ${formatDate(validTill)}. Please provide a new version.`}
                />
            </div>
        )
    }

    return <></>
}

function assembleUpdateRequiredAlertText(validTill?: Date): string  {
    return validTill
        ? `This document expires on ${formatDate(validTill)}. Please provide a new version.`
        : "This document is about to expire. Please provide a new version."
}

function assembleRejectedAlertText(comment?: string): ReactNode {
    const text = "This document has been rejected. Please upload a new one."
    if (comment) {
        return (
            <>
                {text}
                <div className={styles.reason}>
                    <strong>Reason:</strong> {comment}
                </div>
            </>
        )
    }
    return text
}