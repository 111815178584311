import React, {FunctionComponent, useState} from "react"
import {SubmitHandler, useForm} from "react-hook-form"
import {useNavigate} from "react-router-dom"
import Head from "../../../../../tech/head/head.component"
import AccreditedInvestorStatusExplanation from "../../../../../tech/auth/sign-up/ai-explanation.component"
import SubmitButton from "../../../../../tech/form/submit/submit.component"
import {AiStatusDeclarationType} from "./ai-status-declaration.type"
import LoadingDots from "../../../../../tech/loading/dots/dots.component"
import Alert from "../../../../../tech/alert/alert.component"
import {AlertType} from "../../../../../tech/alert/type.enum"
import {DEALS} from "../../../../../paths"
import {InvestorOnboardingType} from "../../onboarding.type"
import {normalizeValues} from "../../../../../tech/form/form.util"
import ExclamationMark from "../../../../../tech/exclamation-mark/mark.component"
import ExclamationMarkInfo from "../../../../../tech/exclamation-mark/info.component"
import Checkbox from "../../../../../tech/form/fields/checkbox/checkbox.component"
import InvestorOnboardingDescription from "../../description/description.component"
import styles from "./ai-status-declaration.module.sass"
import {useApp} from "../../../../../tech/app/context/app.context"

type InvestorOnboardingAiStatusDeclarationProps = {
    updateOnboarding: (onboarding: InvestorOnboardingType) => void
}

const InvestorOnboardingAiStatusDeclaration: FunctionComponent<InvestorOnboardingAiStatusDeclarationProps> = ({ updateOnboarding }) => {
    const app = useApp()
    const [state, setState] = useState<"LOADING" | "NONE" | "ERROR">("NONE")
    const navigate = useNavigate()
    const {
        register,
        handleSubmit,
        formState: {
            errors,
            isDirty,
            isValid
        }
    } = useForm<AiStatusDeclarationType>()

    const onSubmit: SubmitHandler<AiStatusDeclarationType> = async (aiStatusDeclaration) => {
       try {
           setState("LOADING")
           updateOnboarding(await app.fetchClient.investorApi.updateOnboardingAiDeclaration(normalizeValues(aiStatusDeclaration)))
           navigate(DEALS)
       }
       catch (err) {
           console.error(err)
           setState("ERROR")
       }
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to save Accredited Investor status."
                />
            )}
            {(state === "NONE" || state === "ERROR") && (
                <div className={styles.aiStatusDeclaration}>
                    <Head title="Accredited Investor"/>
                    <InvestorOnboardingDescription content={[
                        <>Before you can proceed, we need you to confirm that you are an <strong>Accredited Investor</strong>.</>
                    ]}/>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Checkbox
                            label="I confirm that I'm an Accredited Investor"
                            id="aiStatusDeclaration"
                            errors={errors}
                            reactHookFormRegister={{
                                name: "accreditedInvestor",
                                register,
                                options: {
                                    required: "You're only allowed to proceed if you are an Accredited Investor."
                                }
                            }}
                            exclamationMark={{
                                mark: <ExclamationMark modalId="aiDeclaration"/>,
                                info: (
                                    <ExclamationMarkInfo
                                        modalId="aiDeclaration"
                                        title="Accredited Investor"
                                        content={<AccreditedInvestorStatusExplanation/>}
                                    />
                                ),
                                labelFullWidth: true
                            }}
                        />
                        <SubmitButton
                            label="Confirm"
                            disabled={!isValid || !isDirty}
                        />
                    </form>
                </div>
            )}
        </>
    )
}

export default InvestorOnboardingAiStatusDeclaration